import { useOrganizationData } from "@metronome/context/OrganizationData";
import type { IUser } from "@metronome/types/User";
import type { AxiosError, AxiosResponse } from "axios";
import {
	useMutation,
	type UseMutationResult,
	useQuery,
	useQueryClient,
	queryOptions,
} from "@tanstack/react-query";

import { apiGet, apiPut, type ExpectedErrorResponseType } from "./api";
import { useNavigate } from "@tanstack/react-router";

export const selfQueryOptions = (activeOrganization?: string) =>
	queryOptions({
		queryKey: ["self", activeOrganization],
		queryFn: () =>
			apiGet<IUser>("/self", {
				params: {
					organizationId: activeOrganization,
				},
			}),
		staleTime: Number.POSITIVE_INFINITY,
		gcTime: Number.POSITIVE_INFINITY,
		retry: 3,
	});

export default function useSelf() {
	const { activeOrganization } = useOrganizationData();
	return useQuery(selfQueryOptions(activeOrganization));
}

export function useSelfUpdate(): UseMutationResult<
	AxiosResponse<IUser> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ defaultWorkspaceId?: string }
> {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	return useMutation({
		mutationFn: ({ defaultWorkspaceId }) =>
			apiPut("self", {
				defaultWorkspaceId,
			}),

		onSuccess: (response, { defaultWorkspaceId }) => {
			const { data } = response;
			queryClient.setQueryData(["self"], data);
			navigate({ to: "/" });
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["self"] });
		},
	});
}
