import {
	COMPLIANCE,
	DURATION,
	NODES_TYPE,
	OCCURRENCE,
} from "@metronome/constants/stepInstanceColumns";
import type { IBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { Cell, Row } from "@tanstack/react-table";

import { formatDurationToString } from "./durationHelpers";
import type { IStepInstance } from "@metronome/types/StepInstance";

function replacer(_: unknown, value: unknown): unknown {
	if (typeof value === "undefined") {
		return "";
	}
	if (typeof value === "object" && value == null) {
		return "";
	}
	if (typeof value === "object" && value !== null) {
		const allUndefined = Object.values(value).every((val) => val === undefined);
		if (allUndefined) return "";
		return value;
	}
	return value;
}

function valueMapper(
	id: string,
	value: unknown,
	original: IStepInstance,
): unknown {
	if (id === COMPLIANCE) {
		if (!value) return "";
		const isCompliant = original.assignments.responsibleAssignees.find(
			(assignee) => assignee.id === original.completion?.performedBy.id,
		);
		if (isCompliant) return "Yes";
		return "No";
	}
	if (id === OCCURRENCE) {
		const listName = (value as IBusinessDimensionNode[])
			.map((node) => node.name)
			.join(", ");
		return listName;
	}
	if (id === NODES_TYPE) {
		const listName = (value as IBusinessDimensionNode[])
			.filter((node) => node.nodeType?.name)
			.map((node) => node.nodeType?.name)
			.join(", ");
		if (listName.length === 0) return "";

		return listName;
	}
	if (id === DURATION) {
		const duration = formatDurationToString(value as string);
		return duration;
	}
	return value;
}

export const formatCSVExport = <T>(
	cell: Cell<T, unknown>,
	row: Row<T>,
): string => {
	const id = cell.column.columnDef.id ?? "";
	const value = valueMapper(id, cell.getValue(), row.original as IStepInstance);
	if (typeof value === "string") return value;
	return JSON.stringify(value, replacer);
};
