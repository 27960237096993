import type { IBusinessDimension } from "@metronome/types/BusinessDimension";
import { CubeIcon, GlobeIcon } from "@radix-ui/react-icons";

type IconNodeCategoryProps = {
	category: IBusinessDimension["type"];
};

export const IconNodeCategory: React.FC<IconNodeCategoryProps> = ({
	category,
}) => {
	switch (category) {
		case "warehouse":
			return (
				<svg
					id="Home_bulding"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 58.69 58.69"
				>
					<title>warehouse</title>
					<defs>
						<style>
							{
								".cls-2{fill:wheat;}.cls-2,.cls-3{stroke-width:0px;}.cls-3{fill:#575756;}"
							}
						</style>
					</defs>
					<g id="Home">
						<circle className="cls-2" cx="29.35" cy="29.35" r="28.85" />
					</g>
					<path
						className="cls-3"
						d="m47.78,41.59v-2.73l-.6-.16v-7.7l-6.5-7.41c-3.15-1.44-8.66-.89-8.93-.86v-3.09l-8.78-4.21-10.16,5.1v2.59l-2.3,1.1v17.32h-.52v1.93h38.71v-1.89h-.93Zm-25.16-22.79v2.01l-2.77,1.21v-1.88l2.77-1.34Zm-10.04,23.05h-1.28v-1.52l1.28-.1v1.62Zm0-2.47l-1.28.1v-1.47l1.28-.16v1.52Zm0-2.41l-1.28.16v-1.39l1.28-.26v1.48Zm0-2.43l-1.28.25v-1.2l1.28-.36v1.31Zm0-2.2l-1.28.36v-1.38l1.28-.44v1.46Zm0-2.36l-1.34.46v-1.44l1.34-.52v1.5Zm0-2.48l-1.28.49v-1.35l1.28-.56v1.42Zm0-2.28l-1.34.59v-1.48l1.34-.65v1.54Zm2.88,16.66h-1.78v-1.72l1.78-.13v1.85Zm0-2.71l-1.78.14v-1.58l1.78-.22v1.66Zm0-2.55l-1.78.23v-1.56l1.78-.36v1.69Zm0-2.62l-1.78.34v-1.4l1.78-.5v1.56Zm0-2.45l-1.78.49v-1.54l1.78-.61v1.66Zm0-2.56l-1.78.61v-1.55l1.78-.7v1.63Zm0-2.58l-1.78.68v-1.48l1.78-.77v1.57Zm0-2.45l-1.78.78v-1.59l1.78-.86v1.68Zm3.3,17.94h-2.2v-1.96l2.2-.17v2.12Zm0-2.99l-2.2.17v-1.71l2.2-.27v1.81Zm0-2.71l-2.2.28v-1.77l2.2-.44v1.93Zm0-2.84l-2.2.42v-1.65l2.2-.61v1.84Zm0-2.73l-2.2.61v-1.73l2.2-.76v1.88Zm0-2.77l-2.2.76v-1.68l2.2-.86v1.79Zm0-2.7l-2.2.84v-1.63l2.2-.96v1.76Zm0-2.64l-2.2.96v-1.73l2.2-1.07v1.83Zm3.87,19.41l-2.77-.02v-2.21l2.77-.21v2.44Zm0-3.32l-2.77.22v-1.86l2.77-.34v1.99Zm0-2.9l-2.77.35v-2.01l2.77-.55v2.21Zm0-3.09l-2.77.53v-1.94l2.77-.77v2.18Zm0-3.06l-2.77.77v-1.95l2.77-.96v2.14Zm.1-3.07l-2.87.99v-1.84l2.87-1.12v1.97Zm0-2.84l-2.87,1.09v-1.82l2.87-1.25v1.97Zm5.56,17.86h-.96v-1.62h.96v1.62Zm5.41,0h-1.03v-1.62h1.03v1.62Zm6.09-2.08h-16.21v-.76h16.21v.76Zm0-3.16s-1.74-.43-6.33-.43-9.88.54-9.88.54v-.73s4.94-.57,9.73-.57,6.48.34,6.48.34v.85Zm0-2.92s-2.79-.93-6.81-.93-9.4.85-9.4.85v-.76s4.96-.82,9.22-.82,6.99.81,6.99.81v.85Zm0-3.56s-1.66-1.27-5.67-1.27-10.54,1.67-10.54,1.67v-.78s6.59-1.72,10.46-1.72,5.75,1.09,5.75,1.09v1Zm0-3.12s-1.52-1.5-5.64-1.5c-5.18,0-10.57,1.99-10.57,1.99v-.96s6.52-1.84,10.61-1.84,5.6,1.42,5.6,1.42v.88Zm4.38,14.82h-1.41v-3.45h1.41v3.45Z"
					/>
				</svg>
			);
		case "geography":
			return <GlobeIcon />;
		case "products":
			return <CubeIcon />;
		default:
			return <CubeIcon />;
	}
};
