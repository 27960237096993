import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";

import { MsalProvider } from "@azure/msal-react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faFlag,
	faComment,
	faCircleDot,
	faFileLines,
	faCirclePause,
	faSquare as faSquareRegular,
	faClock as faClockRegular,
} from "@fortawesome/free-regular-svg-icons";
import {
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faArrowDown,
	faArrowRight,
	faArrowUp,
	faArrowUpRightFromSquare,
	faBars,
	faBell,
	faBookmark,
	faBriefcase,
	faCaretDown,
	faCheck,
	faChevronDown,
	faCircle,
	faClock,
	faCodeBranch,
	faDotCircle,
	faEdit,
	faEye,
	faFile,
	faFileArrowUp,
	faFlag as faSolidFlag,
	faHistory,
	faInfoCircle,
	faLink,
	faMapMarkedAlt,
	faPen,
	faPlus,
	faRectangleList,
	faSearch,
	faSpinner,
	faSquare,
	faStickyNote,
	faThumbtack,
	faTimes,
	faTrash,
	faUpload,
	faUsers,
	faFilter,
	faCircleExclamation,
	faListCheck,
	faCalendarCheck,
	faMinus,
	faCircleNodes,
	faCircleCheck,
	faCircleXmark,
	faEllipsisVertical,
	faChevronUp,
	faBolt,
	faArrowsRotate,
	faXmark,
	faSquareCheck,
	faArrowRightFromBracket,
	faChevronRight,
	faLeftRight,
	faPaperclip,
	faChartGantt,
} from "@fortawesome/free-solid-svg-icons";
import type { RouterHistory } from "@sentry/react/types/reactrouter";
import "./utilities.css";
import { useIntl } from "react-intl";
import { msalInstance } from "./authConfig";

import App from "./App";

import { LanguageProvider } from "./i18n/LanguageProvider";

import "./index.scss";
import { Button } from "./components/ui/button";

library.add(
	faPaperclip,
	faLeftRight,
	faArrowRightFromBracket,
	faXmark,
	faFileLines,
	faCirclePause,
	faSquare,
	faSquareRegular,
	faCircleNodes,
	faComment,
	faCircleDot,
	faBars,
	faBell,
	faTimes,
	faCalendarCheck,
	faDotCircle,
	faCircle,
	faCircleExclamation,
	faListCheck,
	faCodeBranch,
	faBriefcase,
	faArrowUpRightFromSquare,
	faFlag,
	faSolidFlag,
	faTrash,
	faAngleLeft,
	faAngleRight,
	faArrowUp,
	faArrowDown,
	faAngleDown,
	faBookmark,
	faThumbtack,
	faMapMarkedAlt,
	faCheck,
	faEdit,
	faBolt,
	faPlus,
	faMinus,
	faSearch,
	faArrowsRotate,
	faLink,
	faCaretDown,
	faFileArrowUp,
	faRectangleList,
	faStickyNote,
	faInfoCircle,
	faSquare,
	faUsers,
	faPen,
	faChevronDown,
	faChevronUp,
	faChevronRight,
	faSpinner,
	faUpload,
	faClock,
	faClockRegular,
	faArrowRight,
	faEye,
	faFile,
	faHistory,
	faFilter,
	faCircleCheck,
	faSquareCheck,
	faCircleXmark,
	faEllipsisVertical,
	faChartGantt,
);

declare global {
	interface Window {
		VITE_AZUREAD_CLIENT_ID: string;
		VITE_AZUREAD_AUTHORITY: string;
		VITE_AZUREAD_API_SCOPE: string;
		VITE_OPENID_CLIENT_ID: string;
		VITE_OPENID_AUTHORITY: string;
		VITE_OPENID_API_SCOPE?: string;
		VITE_API_URL: string;
		VITE_BACKOFFICE_URL: string;
		VITE_PUBLIC_URL: string;
		VITE_SENTRY_URL?: string;
		VITE_SENTRY_ENV?: string;
		VITE_WEBSOCKET_URL: string;
	}
}

type FallbackRender = (errorData: {
	error: Error;
	componentStack: string | null;
	eventId: string | null;
	resetError(): void;
}) => React.ReactElement;

const ErrorComponent: FallbackRender = ({
	error,
	componentStack,
	resetError,
}): React.ReactElement => {
	const intl = useIntl();
	return (
		<div className="flex flex-col items-center justify-center h-screen bg-red-100">
			<h1 className="text-3xl font-bold text-red-700">
				{intl.formatMessage({ id: "ERROR_ENCOUNTERED" })}
			</h1>
			<div>{componentStack}</div>
			<p className="mt-4 text-lg text-red-600">{error.toString()}</p>
			<Button variant="secondary" onClick={resetError}>
				{intl.formatMessage({ id: "RESTART_APP" })}
			</Button>
		</div>
	);
};

const history = createBrowserHistory();

Sentry.init({
	dsn: import.meta.env.PROD
		? window.VITE_SENTRY_URL || import.meta.env.VITE_SENTRY_URL
		: "",
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: [
				window.VITE_API_URL || import.meta.env.VITE_API_URL,
			],
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(
				history as unknown as RouterHistory, // ugly but no time to understand this mess
			),
		}),
	],
	environment: window.VITE_SENTRY_ENV || import.meta.env.VITE_SENTRY_ENV,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.2,
});

const prepare = async (): Promise<void> => {
	if (import.meta.env.DEV) {
		const { worker } = await import("../mocks/browser");
		worker.stop();
	}
};

window.addEventListener("vite:preloadError", () => {
	window.location.reload(); // for example, refresh the page
});

prepare().then(() => {
	const container = document.getElementById("root");
	const root = createRoot(container);
	root.render(
		<React.StrictMode>
			<MsalProvider instance={msalInstance}>
				<Sentry.ErrorBoundary fallback={ErrorComponent}>
					<LanguageProvider>
						<App />
					</LanguageProvider>
				</Sentry.ErrorBoundary>
			</MsalProvider>
		</React.StrictMode>,
	);
});
