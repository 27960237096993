import { z } from "zod";
import type { IParty } from "./Party";
import type { IUserRoleAssignmentsMap } from "./UserRoleAssignmentsMap";

export const ERoles = z.enum([
	"responsible",
	"accountable",
	"consulted",
	"informed",
]);
export type ERoles = z.infer<typeof ERoles>;

export const raciRoles = {
	R: ERoles.enum.responsible,
	A: ERoles.enum.accountable,
	C: ERoles.enum.consulted,
	I: ERoles.enum.informed,
};

// some TS gym -> getting only the keys of IUserRoleAssignmentsMap which value match IRoleAssignment[]
type GetAssigneesKeys<T extends object> = {
	[K in keyof T]: T[K] extends IParty[] ? K : never;
}[keyof T];

type MapRoled = Record<ERoles, GetAssigneesKeys<IUserRoleAssignmentsMap>>;

export const mapRolesAssignments: MapRoled = {
	[ERoles.enum.informed]: "informedAssignees",
	[ERoles.enum.consulted]: "consultedAssignees",
	[ERoles.enum.accountable]: "accountableAssignees",
	[ERoles.enum.responsible]: "responsibleAssignees",
};
