import {
	type ETypeOfSchedule,
	useUpdateStepInstanceSchedule,
} from "@metronome/api/useStepInstance";
import { DateTimePicker } from "@metronome/components/ui/dateTimePickerInput";

type UpdateStepInstanceDateProps = {
	stepInstanceId: string;
	processInstanceId: string;
	typeOfSchedule: ETypeOfSchedule;
	initialDate: Date;
};

export const UpdateStepInstanceDate: React.FC<UpdateStepInstanceDateProps> = ({
	stepInstanceId,
	processInstanceId,
	typeOfSchedule,
	initialDate,
}) => {
	const { mutate } = useUpdateStepInstanceSchedule(
		stepInstanceId,
		processInstanceId,
	);

	const handlePlannedOnChange = (date?: Date): void => {
		if (!date) return;
		mutate({ typeOfSchedule, date });
	};
	return (
		<DateTimePicker
			key={stepInstanceId + initialDate?.getTime()}
			initialDate={initialDate}
			onValidate={(date) => handlePlannedOnChange(date)}
		/>
	);
};
