import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/$workspaceId/stream/$streamId/")({
	beforeLoad: ({ params }) => {
		throw redirect({
			from: "/$workspaceId/stream/$streamId",
			to: "/$workspaceId/stream/$streamId/$state",
			params: {
				state: "active",
				streamId: params.streamId,
			},
		});
	},
});
