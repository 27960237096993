import { FormattedMessage } from "react-intl";
import { z } from "zod";
import { IParty } from "./Party";
import { ThirdApps } from "./ThirdApp";

const SystemDoer = z.object({
	type: z.literal("system"),
	name: ThirdApps,
});

const JobDoer = z.object({
	type: z.literal("job"),
});

const UnknownDoer = z.object({
	type: z.literal("none"),
});

const AdminDoer = z.object({
	type: z.literal("metronomeSupport"),
});

export const IDoer = z.discriminatedUnion("type", [
	SystemDoer,
	JobDoer,
	UnknownDoer,
	AdminDoer,
	IParty,
]);

export type IDoer = z.infer<typeof IDoer>;

export function getDoerName(doer: IDoer): string | JSX.Element {
	switch (doer.type) {
		case "metronomeSupport":
			return <FormattedMessage id="METRONOME_SUPPORT" />;
		case "system":
			return doer.name;
		case "party":
			return `${doer?.firstName || ""} ${doer?.lastName || ""}`;
		default:
			return "";
	}
}

export function doerEquals(a: IDoer, b: IDoer): boolean {
	if (a.type !== b.type) {
		return false;
	}
	if (
		(a.type === "system" && b.type === "system") ||
		(a.type === "party" && b.type === "party")
	) {
		return a.name === b.name;
	}
	return true;
}
