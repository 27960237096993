import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocalStorage } from "usehooks-ts";
import * as Sentry from "@sentry/react";

import Logo from "@metronome/assets/logotype-couleur.svg?react";
import MicrosoftLogo from "@metronome/assets/microsoft.svg?react";
import { loginRequest } from "@metronome/authConfig";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const Login = (): JSX.Element => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const { instance, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [activateIdentity, setActivateIdentity] = useLocalStorage<string>(
		"activateIdentity",
		"false",
	);

	function handleSwitchToIdentity(): void {
		setActivateIdentity(activateIdentity === "true" ? "false" : "true");
		window.location.reload();
	}

	async function handleLogin(): Promise<void> {
		setIsLoading(true);
		/**
		 * loginRedirect must redirect to an msal enabled url
		 */
		if (!isAuthenticated && inProgress === InteractionStatus.None) {
			await instance.handleRedirectPromise();
			const accounts = instance.getAllAccounts();
			if (accounts.length === 0) {
				// No user signed in
				await instance
					.loginRedirect({
						...loginRequest,
					})
					.catch((e) => {
						console.error(e);
						Sentry.captureException(e);
					});
			}
		}
	}

	return (
		<div className="m-4 bg-white rounded shadow-sm p-5 p-lg-15 flex flex-col items-center">
			<div
				className="flex flex-row justify-content-center items-center"
				style={{ maxWidth: "400px" }}
			>
				<div className="w-16 m-auto">
					<Logo />
				</div>
			</div>
			<p className="text-center font-semibold mt-2">
				{intl.formatMessage({ id: "AUTHENTICATION.LOGIN_TAGLINE" })}
			</p>
			<h1 className="my-8 text-center text-2xl font-bold">
				{intl.formatMessage({ id: "AUTHENTICATION.LOGIN_H1" })}
			</h1>
			{activateIdentity === "true" ? (
				<button
					className="w-56 text-center shadow-sm bg-slate-200 m-auto py-2 px-4 hover:bg-slate-300 transition-colors"
					type="button"
					onClick={async () => {
						await handleLogin();
					}}
				>
					{intl.formatMessage({ id: "AUTHENTICATION.LOGIN_OIDC" })}
				</button>
			) : (
				<button
					className="w-56 flex flex-row shadow-sm bg-slate-200 m-auto py-2 px-4 gap-2 hover:bg-slate-300 transition-colors"
					type="button"
					disabled={inProgress !== InteractionStatus.None || isAuthenticated}
					onClick={async () => {
						await handleLogin();
					}}
				>
					<MicrosoftLogo />
					{intl.formatMessage({ id: "AUTHENTICATION.LOGIN" })}
					{isLoading && (
						<span className="spinner-border spinner-border-sm align-middle ms-2" />
					)}
				</button>
			)}
			<div className="mt-12 text-center">
				<a href="mailto:contact@process-metronome.com">
					{intl.formatMessage({ id: "AUTHENTICATION.LOGIN_HELP" })}
				</a>
				<br />
				<button
					className="w-56 inline text-xs"
					type="button"
					onClick={handleSwitchToIdentity}
				>
					{intl.formatMessage({ id: "IDENTITY_LOGIN" })}
					{activateIdentity === "true" ? " (on)" : " (off)"}
				</button>
			</div>
		</div>
	);
};
