import React, { useCallback } from "react";

import { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import { EditableCell } from "@metronome/components/EditableCell";
import { HighlightWords } from "@metronome/components/HighlightWords";
import { MultiSelect } from "@metronome/components/MultiSelect";
import type {
	IBusinessDimensionNode,
	IMetadataValue,
} from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { getInputType } from "@metronome/utils/metadataType";
import type { MultiValue } from "react-select";

type EditSingleMetadataProps = {
	instance: IStepInstance | IProcessInstance;
	context: Context;
	metadataDefinition: IMetadataDefinition;
	query?: string;
};

type MultiSelectMetadataProps = {
	metadataDefinition: IMetadataDefinition;
	metaData?: IMetadataValue;
	businessDimensions?: IBusinessDimensionNode;
	mutateNodeName: ReturnType<typeof useUpsertMetadataValue>["mutate"];
};

type Option = { label: string; value: string };

export const MultiSelectMetadata: React.FC<MultiSelectMetadataProps> = ({
	metadataDefinition,
	metaData,
	businessDimensions,
	mutateNodeName,
}) => {
	const [value, setValue] = React.useState<readonly Option[]>(
		metaData?.value
			? (metaData.value as string[]).map((v) => ({
					label: v,
					value: v,
			  }))
			: [],
	);

	const [isDirty, setIsDirty] = React.useState(false);

	const onMenuToggle = useCallback(() => {
		if (isDirty && businessDimensions?.id) {
			mutateNodeName({
				definitionId: metadataDefinition.id,
				nodeId: businessDimensions?.id,
				metadataValues: [
					metaData?.id
						? {
								id: metaData.id,
								value: value.map((v) => v.value),
						  }
						: {
								value: value.map((v) => v.value),
						  },
				],
			});
		}
	}, [
		businessDimensions?.id,
		isDirty,
		metaData?.id,
		metadataDefinition.id,
		mutateNodeName,
		value,
	]);

	if (metadataDefinition.enum)
		return (
			<MultiSelect
				isMulti
				options={metadataDefinition.enum.map((v) => ({
					label: v,
					value: v,
				}))}
				value={value}
				onMenuClose={onMenuToggle}
				onChange={(val: MultiValue<Option>) => {
					setIsDirty(true);
					setValue(val);
					return val;
				}}
			/>
		);

	return null;
};

export const EditSingleMetadata: React.FC<EditSingleMetadataProps> = ({
	instance,
	context,
	metadataDefinition,
	query,
}) => {
	const { mutate: mutateNodeName } = useUpsertMetadataValue(
		instance.id,
		context,
	);

	const getBusinessDimensions = instance.businessDimensions?.find((dimension) =>
		dimension.metadataValues.some(
			(metaData) => metaData.definition.id === metadataDefinition.id,
		),
	);
	const getMetaData = getBusinessDimensions?.metadataValues.find(
		(metaData) => metaData.definition.id === metadataDefinition.id,
	);

	if (metadataDefinition.restrictedNodeTypes.length) {
		const nodeTypesMatch = instance.businessDimensions?.some((bd) =>
			metadataDefinition.restrictedNodeTypes.find(
				(rNodeTypes) => rNodeTypes?.id === bd.nodeType?.id,
			),
		);

		if (getMetaData?.value && !nodeTypesMatch) {
			return (
				<HighlightWords
					content={
						Array.isArray(getMetaData.value)
							? getMetaData.value.join()
							: getMetaData.value
					}
					query={query ?? ""}
				/>
			);
		}
	}

	if (metadataDefinition.type === "singleSelect") {
		return (
			<select
				className="w-full"
				value={getMetaData?.value}
				onChange={(e) => {
					if (getBusinessDimensions?.id && getMetaData?.id) {
						mutateNodeName({
							definitionId: getMetaData.definition.id,
							nodeId: getBusinessDimensions.id,
							metadataValues: [
								{
									id: getMetaData.id,
									value: e.target.value,
								},
							],
						});
					}
				}}
			>
				<option key="empty" />
				{metadataDefinition.enum?.map((v) => (
					<option key={v} value={v}>
						{v}
					</option>
				))}
			</select>
		);
	}

	if (metadataDefinition.type === "multiSelect") {
		if (
			metadataDefinition.enum &&
			getMetaData &&
			Array.isArray(getMetaData?.value)
		) {
			return (
				<MultiSelectMetadata
					metaData={getMetaData}
					businessDimensions={getBusinessDimensions}
					metadataDefinition={metadataDefinition}
					mutateNodeName={mutateNodeName}
				/>
			);
		}
	}

	if (typeof getMetaData?.value === "string") {
		return (
			<EditableCell
				value={getMetaData?.value}
				type={getInputType(metadataDefinition.type)}
				onValidate={(v: string) => {
					if (getBusinessDimensions?.id && getMetaData?.id) {
						mutateNodeName({
							definitionId: getMetaData.definition.id,
							nodeId: getBusinessDimensions.id,
							metadataValues: [{ id: getMetaData.id, value: v }],
						});
					}
				}}
			/>
		);
	}
	return <span>fail to parse data</span>;
};
