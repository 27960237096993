import { useOrganizationData } from "@metronome/context/OrganizationData";
import type { NodeReferenceLightModel } from "@metronome/features/Modals/ReferenceModal";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { INodeReferenceSpec } from "@metronome/types/BusinessDimension";
import {
	type ICreateableMetaValue,
	IMetadataDefinition,
	type IMetaValue,
} from "@metronome/types/MetadataDefinition";
import type { AxiosError, AxiosResponse } from "axios";
import { FormattedMessage } from "react-intl";
import {
	useMutation,
	type UseMutationResult,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import api, {
	apiGet,
	apiPut,
	type ExpectedErrorResponseType,
	HTTPMethod,
} from "./api";
import { stepInstanceKeys } from "./useStepInstance";
import type { Context } from "@metronome/types/Context";
import { processInstanceKeys } from "./useProcessInstance";

type Params = {
	[key: string]: string | string[] | number | undefined;
};

const metaDefinitionKeys = {
	all: (workspaceId: string) => [workspaceId, "metadata-definitions"] as const,
	filter: (workspaceId: string, params: Params) =>
		[...metaDefinitionKeys.all(workspaceId), params] as const,
};

const referenceSpecKeys = {
	all: (workspaceId: string) => [workspaceId, "node-reference-spec"] as const,
	filter: (workspaceId: string, params: Params) =>
		[...referenceSpecKeys.all(workspaceId), params] as const,
};
const GetMetadataDefinitionRequest = z.object({
	organizationId: z.string().optional(),
	nodeIds: z.array(z.string().min(1)).optional(),
	processStreamId: z.string().optional(),
});
const GetMetadataDefinitionResponse = z.array(IMetadataDefinition);

const fetchMetadataDefinition = api<
	z.infer<typeof GetMetadataDefinitionRequest>,
	z.infer<typeof GetMetadataDefinitionResponse>
>({
	method: HTTPMethod.enum.GET,
	requestSchema: GetMetadataDefinitionRequest,
	responseSchema: GetMetadataDefinitionResponse,
});

export default function useMetadataDefinitions(
	options?: { nodeIds?: string[]; processStreamId?: string } | undefined,
): UseQueryResult<IMetadataDefinition[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();
	if (!workspaceId) {
		throw new Error("useMetadataDefinitions: workspaceId is not defined");
	}
	return useQuery({
		queryKey: metaDefinitionKeys.filter(workspaceId, {
			organizationId: activeOrganization,
			nodeIds: options?.nodeIds,
			processStreamId: options?.processStreamId,
		}),

		queryFn: () =>
			fetchMetadataDefinition(`ws/${workspaceId}/metadata-definitions`, {
				organizationId: activeOrganization,
				nodeIds: options?.nodeIds,
				processStreamId: options?.processStreamId,
			}),

		staleTime: Number.POSITIVE_INFINITY,
		gcTime: Number.POSITIVE_INFINITY,
		refetchOnWindowFocus: false,
	});
}

export function useUpsertMetadataValue(
	contextId: string,
	context: Context,
): UseMutationResult<
	AxiosResponse<void> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{
		definitionId: string;
		nodeId: string;
		metadataValues: IMetaValue[] | ICreateableMetaValue[];
	}
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateNodeName: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ definitionId, nodeId, metadataValues }) =>
			apiPut(`ws/${workspaceId}/metadata-values/${definitionId}`, {
				nodeId,
				metadataValues,
			}),

		onError: (error) => {
			toast.error(`Error: ${error.response?.data?.message ?? error.message}`);
		},

		onSuccess: (_, { nodeId }) => {
			if (context === "process-instances") {
				queryClient.invalidateQueries({
					queryKey: processInstanceKeys.single(workspaceId, contextId),
				});
			} else {
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.single(workspaceId, contextId),
				});
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.all(workspaceId),
				});
			}
			toast.success(<FormattedMessage id="SUCCESS" />);
		},
	});
}

export function useNodeReferenceSpecs(
	nodeIds?: string[],
): UseQueryResult<INodeReferenceSpec[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useNodeReferenceSpecs: workspaceId is not defined");
	}
	return useQuery({
		queryKey: referenceSpecKeys.filter(workspaceId, {
			nodeIds,
		}),

		queryFn: () =>
			apiGet<INodeReferenceSpec[]>(`ws/${workspaceId}/node-reference-specs`, {
				params: {
					nodeIds,
				},
			}),

		enabled: !!nodeIds,
	});
}

export function useUpdateNodeReferences(
	stepInstanceId: string,
): UseMutationResult<
	AxiosResponse<void> | { data: undefined },
	Error,
	{
		nodeId: string;
		referenceSpecId: string;
		nodeReferences: NodeReferenceLightModel[];
	}
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateNodeReferences: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ nodeId, referenceSpecId, nodeReferences }) =>
			apiPut(`ws/${workspaceId}/node-references/`, {
				nodeId,
				referenceSpecId,
				nodeReferences,
			}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: stepInstanceKeys.single(workspaceId, stepInstanceId),
			});
		},

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}
