import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimeCell from "@metronome/components/DateCell";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styles from "./editableField.module.scss";

type Props = {
	value?: string;
	type?: string;
	min?: string;
	max?: string;
	isLoading?: boolean;
	displayInput?: boolean;
	onValidate?: (value: string) => void;
};

export const EditableField = React.forwardRef<HTMLDivElement, Props>(
	(
		{
			value = "",
			min = "",
			max = "",
			type = "text",
			isLoading = false,
			displayInput = false,
			onValidate = () => {},
		},
		forwardedRef,
	): JSX.Element => {
		const initialValue = useRef(value);
		const [showInput, setShowInput] = useState<boolean>(displayInput);
		const [showIsLoading, setIsLoading] = useState<boolean>(isLoading);
		const [newValue, setNewValue] = useState<string>(value);
		const [errorMessage, setErrorMessage] = useState<string>();
		const hasValue = value && value !== "";
		const ref = useRef<HTMLInputElement>(null);

		const displayValue = (): JSX.Element => {
			if (!hasValue) return <span> N/A</span>;
			if (type === "datetime-local") return <DateTimeCell datetime={value} />;
			if (type === "date") return <span>{value}</span>;
			return <span> {value} </span>;
		};

		const handleCancel = (): void => {
			setNewValue(value);
			setShowInput(false);
			setIsLoading(false);
		};

		const handleValidate = (): void => {
			if (initialValue.current === newValue) {
				setShowInput(false);
				return;
			}
			onValidate(newValue);
			setShowInput(false);
		};

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
			setNewValue(event.target.value);
			setErrorMessage(undefined);
			setIsLoading(false);
			if (type === "date") {
				onValidate(newValue);
				setShowInput(false);
			}
		};

		useEffect(() => {
			if (!isLoading) {
				setShowInput(false);
			}
		}, [isLoading]);

		return (
			<div ref={forwardedRef} className="relative flex flex-col gap-2">
				{showInput ? (
					<input
						type={type}
						ref={ref}
						onClick={() => {
							ref?.current?.showPicker();
						}}
						min={min}
						max={max}
						className={clsx(
							"text-base px-2 grow border-b-2",
							showInput ? "block" : "hidden",
						)}
						onChange={handleChange}
						value={newValue}
					/>
				) : (
					<>
						<button
							type="button"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowInput(true);
							}}
							className={styles.editableField}
						>
							<div className="flex">
								<div className={clsx("truncate", showInput ? "hidden" : "")}>
									{displayValue()}
								</div>
								<span
									className={clsx(
										"spinner-border spinner-border-sm absolute end-0 me-n4 top-0",
										showIsLoading ? "" : "hidden",
									)}
								/>
							</div>
						</button>
						<span className="text-red-500">{errorMessage}</span>
					</>
				)}

				<div
					className="absolute mt-2 end-0 top-5"
					style={{ zIndex: "1", height: showInput ? "40px" : "0px" }}
				>
					<button
						type="button"
						className={clsx(
							styles.validateButton,
							"btn p-0",
							!showInput ? "hidden" : "",
						)}
						disabled={showIsLoading}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleValidate();
						}}
					>
						<FontAwesomeIcon
							className="text-white text-center"
							icon={["fas", "check"]}
						/>
					</button>
					<button
						type="button"
						className={clsx(
							styles.cancelButton,
							"btn p-0 text-center",
							!showInput ? "hidden" : "",
						)}
						onClick={handleCancel}
						disabled={showIsLoading}
					>
						<FontAwesomeIcon
							className="text-white text-center"
							icon={["fas", "times"]}
						/>
					</button>
				</div>
			</div>
		);
	},
);

EditableField.displayName = "EditableField";

export default EditableField;
