import useSelf from "@metronome/api/useSelf";
import { IModelReference } from "@metronome/types/ModelReference";
import { IFiltering } from "@metronome/types/ProcessStream";
import React from "react";
import { useIntl } from "react-intl";

type Props = {
	filterings: IFiltering[];
};

export const FilterWording: React.FC<Props> = ({ filterings }) => {
	const intl = useIntl();
	const { data: user } = useSelf();

	const baseMessage = intl.formatMessage({
		id: "PROCESS_STREAM.FILTERING.BASE",
	});
	const itemInMessage = intl.formatMessage({
		id: "PROCESS_STREAM.FILTERING.ITEM_IN",
	});
	const withInMessage = intl.formatMessage({
		id: "PROCESS_STREAM.FILTERING.WITHIN",
	});
	const andText = intl.formatMessage({ id: "PROCESS_STREAM.FILTERING.AND" });
	const baseBackOffice =
		window.VITE_BACKOFFICE_URL || import.meta.env.VITE_BACKOFFICE_URL;

	function getSpanOrLink(node: IModelReference): JSX.Element {
		return (
			<>
				{user?.canAccessStudio ? (
					<a
						href={`${baseBackOffice}/business-dimension-nodes/${node.id}`}
						target="_blank"
						rel="noreferrer"
						className="italic"
					>
						{node.name}
					</a>
				) : (
					<span className="italic underline">{node.name}</span>
				)}
			</>
		);
	}

	function wordForFilterMethod(filtering: IFiltering): JSX.Element {
		return (
			<>
				<span>{itemInMessage}</span>
				{getSpanOrLink(filtering.values.node)}
				{wordForLabels(filtering)}
			</>
		);
	}

	function wordForLabels(filtering: IFiltering): JSX.Element {
		return (
			<>
				{filtering.values.labels?.length ? (
					<>
						<span>{withInMessage}</span>
						<span>Label(s)</span>
						{filtering.values.labels.map((l) => (
							<span key={l.id} className="italic underline">
								{l.name} ({l.operation})
							</span>
						))}
					</>
				) : undefined}
			</>
		);
	}

	function wordForSpanMethod(filtering: IFiltering): JSX.Element {
		return (
			<>
				<span className="italic underline">
					{filtering.values?.level?.name}
				</span>
				{filtering.values.node?.name ? (
					<>
						<span>{withInMessage}</span>
						{getSpanOrLink(filtering.values.node)}
						{wordForLabels(filtering)}
					</>
				) : undefined}
			</>
		);
	}

	function wording(filtering: IFiltering): JSX.Element {
		switch (filtering.method) {
			case "Filter":
				return wordForFilterMethod(filtering);
			case "Span":
				return wordForSpanMethod(filtering);
			default:
				return <span>unknown</span>;
		}
	}

	const wordingByFilterings = filterings.map((filtering, index) => {
		return (
			<>
				{index > 0 && <span>{andText}</span>}
				{wording(filtering)}
			</>
		);
	});

	if (!wordingByFilterings?.length) {
		return <p>No available data</p>;
	}
	return (
		<>
			<p className="flex gap-2">
				{baseMessage}
				{wordingByFilterings}
			</p>
		</>
	);
};
