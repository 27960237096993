import type React from "react";
import { type ChangeEvent, useEffect, useRef, useState } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";

import useSelf from "@metronome/api/useSelf";
import Avatar from "@metronome/components/Avatar";
import Button from "@metronome/components/Button";
import { getInitials } from "@metronome/utils/formatText";
import { getUserUri } from "@metronome/utils/user";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./Note.module.scss";
import "./noteOverride.scss";

export const WriteNote: React.FC<{
	content: string;
	onChange: (value: string) => void;
	onSubmit: () => void;
	initialIsEditing?: boolean;
	showAvatar?: boolean;
}> = ({ content, onChange, onSubmit, initialIsEditing, showAvatar = true }) => {
	const { data: user } = useSelf();
	const textAreadRef = useRef<HTMLTextAreaElement>(null);
	const [isEditing, setIsEditing] = useState(initialIsEditing ?? false);
	const intl = useIntl();

	useKeyboardShortcut(
		["m"],
		() => {
			setIsEditing(true);
		},
		{
			ignoreInputFields: true,
			repeatOnHold: false,
		},
	);

	useEffect(() => {
		if (textAreadRef?.current && isEditing) {
			const timer = setTimeout(() => {
				textAreadRef?.current?.focus();
			}, 0);
			return () => {
				clearTimeout(timer);
			};
		}
		return () => null;
	}, [isEditing]);

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			role="button"
			className={styles.button}
			onClick={() => {
				setIsEditing(true);
			}}
		>
			<div className="flex flex-row items-start gap-2 w-auto py-8 m-2">
				{!!showAvatar && (
					<Avatar
						size={32}
						src={getUserUri(user)}
						alt={getInitials(user?.firstName, user?.lastName)}
					/>
				)}
				<div className={styles.note}>
					<div className={isEditing ? "block  px-4" : "hidden"}>
						{isEditing && (
							<textarea
								ref={textAreadRef}
								readOnly={!isEditing}
								value={content || ""}
								className="w-full border border-input shadow-sm p-2"
								onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
									onChange(e.target.value)
								}
								placeholder={intl.formatMessage({
									id: "NOTES.BODY.PLACEHOLDER",
								})}
							/>
						)}
						<div className="flex flex-row gap-4 py-2">
							<Button
								appearance="link"
								onClick={(e) => {
									onSubmit();
									setIsEditing(false);
									e.preventDefault();
									e.stopPropagation();
									e.nativeEvent.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();
								}}
							>
								<FormattedMessage id="SAVE" />
							</Button>
							<Button
								appearance="default"
								onClick={(e) => {
									setIsEditing(false);
									e.preventDefault();
									e.stopPropagation();
									e.nativeEvent.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();
								}}
							>
								<FormattedMessage id="CANCEL" />
							</Button>
						</div>
					</div>

					{!isEditing && (
						<div className="flex flex-col px-4">
							<input className={styles.input} placeholder="add a comment..." />
							<small className="text-start pt-2">
								<strong className="text-slate-600">
									<FormattedMessage id="PRO_TIP" />:
								</strong>{" "}
								<FormattedMessage id="PRESS" />{" "}
								<span className={styles.key}>M</span>{" "}
								<FormattedMessage id="TO_COMMENT" />
							</small>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const EditNote: React.FC<{
	content: string;
	onChange: (value: string) => void;
	onSubmit: () => void;
	onCancel: () => void;
	initialIsEditing?: boolean;
}> = ({ content, onChange, onSubmit, onCancel, initialIsEditing }) => {
	const textAreadRef = useRef<HTMLTextAreaElement>(null);
	const [isEditing, setIsEditing] = useState(initialIsEditing ?? false);
	const intl = useIntl();

	return (
		<button
			className={styles.button}
			type="button"
			onClick={() => {
				setIsEditing(true);
			}}
		>
			<div className="flex flex-row items-start gap-2 w-auto">
				<div className={styles.note}>
					<div>
						{isEditing && (
							<textarea
								ref={textAreadRef}
								readOnly={!isEditing}
								className={styles.textarea}
								value={content || ""}
								onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
									onChange(e.target.value)
								}
								placeholder={intl.formatMessage({
									id: "NOTES.BODY.PLACEHOLDER",
								})}
							/>
						)}
						<div className="flex flex-row gap-4 py-2">
							<Button
								appearance="link"
								onClick={() => {
									onSubmit();
									setIsEditing(false);
								}}
							>
								<FormattedMessage id="SAVE" />
							</Button>
							<Button
								appearance="default"
								onClick={() => {
									setIsEditing(false);
									onCancel();
								}}
							>
								<FormattedMessage id="CANCEL" />
							</Button>
						</div>
					</div>
				</div>
			</div>
		</button>
	);
};
