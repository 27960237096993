import useMetadataDefinitions from "@metronome/api/useMetadataDefinitions";
import type { SavedTable } from "@metronome/api/useSaveTableViews";
import { useGetStepInstancesFilters } from "@metronome/api/useStepInstance";
import StepInstancesTab from "@metronome/features/StepInstancesTab";
import { useRACIData } from "@metronome/context/RACIData";
import type { ProcessState } from "@metronome/types/ProcessInstance";
import type { EResolution } from "@metronome/types/Resolution";
import { raciRoles } from "@metronome/types/Roles";
import type { StepInstanceContext } from "@metronome/types/StepInstance";
import type React from "react";
import LoadingMetronome from "../LoadingMetronome";
import type { UserPreference } from "@metronome/types/UserPreference";
import { useActiveViewData } from "@metronome/context/ActiveViewData";

type Props = {
	resolutions: EResolution[];
	processStates?: ProcessState[];
	context: StepInstanceContext;
	preferenceKey: string;
	savedViews?: SavedTable[];
	workspaceViews?: SavedTable[];
	preferences?: UserPreference;
};

export const UserStepsConfigLoader: React.FC<Props> = ({
	preferenceKey,
	context,
	processStates,
	resolutions,
	savedViews,
	workspaceViews,
	preferences,
}) => {
	const { activeView } = useActiveViewData();
	const { RACI } = useRACIData();
	const roles = RACI.map(
		(letter) => raciRoles[letter as keyof typeof raciRoles],
	);

	const { data: filters } = useGetStepInstancesFilters();
	const { data: metadataDefinitions, isFetched } = useMetadataDefinitions();

	if (savedViews && workspaceViews && isFetched && filters) {
		return (
			<StepInstancesTab
				key={preferenceKey + activeView?.id}
				preferenceKey={preferenceKey}
				resolutions={resolutions}
				processStates={processStates}
				roles={context === "activities" ? undefined : roles}
				context={context}
				preferences={preferences}
				savedViews={savedViews}
				workspaceViews={workspaceViews}
				metadataDefinitions={metadataDefinitions}
				filters={filters}
			/>
		);
	}
	return <LoadingMetronome />;
};
