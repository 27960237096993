import type { Row } from "@tanstack/react-table";
import type React from "react";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@metronome/components/ui/button";
import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "@metronome/components/ui/sheet";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { SchedulerWrapper } from "../Scheduler";

type SubComponentProps<T> = {
	row: Row<T>;
};

export const GanttChartSheet: React.FC<SubComponentProps<IProcessInstance>> = ({
	row,
}) => {
	return (
		<Sheet>
			<SheetTrigger asChild>
				<Button variant="secondary" size="icon" className="mx-auto block">
					<FontAwesomeIcon
						className="text-slate-600"
						icon={["fas", "chart-gantt"]}
					/>
				</Button>
			</SheetTrigger>
			<SheetContent className="max-h-[65vh]" side="bottom">
				<SheetHeader>
					<SheetTitle>
						<FormattedMessage id="GANTT_VIEW" />
					</SheetTitle>
				</SheetHeader>
				<div className="pt-2 pb-2 h-[65vh] w-full bg-grid-scheduler">
					<SchedulerWrapper
						key={row.original.id}
						id={row.original.id}
						name={row.original.name}
						plannedStart={row.original.schedule?.plannedOn}
						plannedEnd={row.original.schedule?.dueOn}
					/>
				</div>
			</SheetContent>
		</Sheet>
	);
};
