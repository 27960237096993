import { useSingleProcessStreams } from "@metronome/api/useProcessStreamInstances";
import { FallbackView } from "@metronome/components/FallbackView";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import { PageTitle } from "@metronome/context/PageData";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import type React from "react";
import { useIntl } from "react-intl";
import { createFileRoute } from "@tanstack/react-router";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import useUserPreferences from "@metronome/api/useUserPreference";

const ProcessStreamPage: React.FC = () => {
	const intl = useIntl();

	const { state, streamId, workspaceId } = Route.useParams();

	const { data: processStream, isLoading } = useSingleProcessStreams(streamId);

	const { organizations } = useOrganizationData();

	const PREFERENCE_KEY = `metronome.processStream.instances.active.${streamId}`;

	const { data: preferences } = useUserPreferences(PREFERENCE_KEY);

	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	useSetCurrentOrganization(processStream?.organization.id);

	if (!processStream) {
		return <FallbackView />;
	}

	const statesMapper = {
		active: ["active" as const],
		upcoming: ["active" as const, "ready" as const],
		past: ["closed" as const, "archived" as const],
	};

	const timesMapper = {
		active: ["past" as const, "today" as const],
		upcoming: ["future" as const],
		past: ["past" as const],
	};

	return (
		<ProcessStreamTemplate
			workspaceId={workspaceId}
			processStream={processStream}
			isLoading={isLoading}
		>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
			</PageTitle>
			<ActiveViewProvider preferences={preferences}>
				<UserProcessConfigLoader
					context={state as ProcessContext}
					key={state}
					states={statesMapper[state as keyof typeof statesMapper]}
					times={timesMapper[state as keyof typeof timesMapper]}
					processStreamId={streamId}
					preferenceKey={PREFERENCE_KEY}
					preferences={preferences}
				/>
			</ActiveViewProvider>
		</ProcessStreamTemplate>
	);
};

export const Route = createFileRoute("/$workspaceId/stream/$streamId/$state")({
	component: ProcessStreamPage,
});
