import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BulkUpdateInstance } from "@metronome/features/BulkUpdateInstance";
import type { IProcessStages } from "@metronome/types/ProcessStages";
import { EResolution } from "@metronome/types/Resolution";
import type React from "react";
import {
	CollapsibleContent,
	CollapsibleRoot,
	CollapsibleTrigger,
} from "../Collapsible";
import { StepInstanceIcon } from "../IconStepInstance";
import { SingleStepInstance } from "../SingleStepInstance";
import styles from "./stepOrGroup.module.scss";

type Props = {
	stage: IProcessStages;
	firstOpenStepGroup?: string;
	processInstanceId?: string;
};
export const StepOrStepsGroup: React.FC<Props> = ({
	stage,
	firstOpenStepGroup,
	processInstanceId,
}) => {
	return (
		<div>
			{stage.steps?.map((stepGroup) => {
				if (stepGroup.stepInstances.length === 1)
					return (
						<SingleStepInstance
							type={stepGroup.stepInstances[0].type}
							key={stepGroup.stepInstances[0].id}
							stepInstance={stepGroup.stepInstances[0]}
							name={stepGroup.name}
						/>
					);

				const doneStepsInstances = stepGroup.stepInstances.filter(
					(instance) => instance.resolution === EResolution.enum.done,
				);
				return (
					<CollapsibleRoot
						className={`relative ${styles.container}`}
						defaultOpen={stepGroup.id === firstOpenStepGroup}
						key={stepGroup.id}
					>
						<CollapsibleTrigger asChild>
							<div className="group/trigger flex flex-row items-center justify-between py-2 pe-4 ps-8 hover:bg-blue-100 data-[state=open]">
								<span className={`${styles.name} truncate`}>
									<StepInstanceIcon type={stepGroup.stepInstances[0].type} />
									<span className={`${styles.name} ps-2`}>
										{stepGroup.name}
									</span>
								</span>
								<div className="flex flex-row items-center gap-2">
									<div className={styles.tag}>
										{`${doneStepsInstances.length}/${stepGroup.stepInstances.length}`}
										<FontAwesomeIcon
											className={`${styles.iconSmaller} group-data-[state=open]/trigger:rotate-90`}
											icon={["fas", "chevron-right"]}
										/>
									</div>
									<BulkUpdateInstance
										stepId={stepGroup.id}
										processInstanceId={processInstanceId ?? ""}
									/>
								</div>
							</div>
						</CollapsibleTrigger>
						<CollapsibleContent>
							<div>
								{stepGroup.stepInstances.map((instance) => (
									<SingleStepInstance
										type={stepGroup.stepInstances[0].type}
										key={instance.id}
										stepInstance={instance}
										name={instance.businessDimensions
											.map((bd) => bd.name)
											.join(", ")}
										isInAGroup
									/>
								))}
							</div>
						</CollapsibleContent>
					</CollapsibleRoot>
				);
			})}
		</div>
	);
};
