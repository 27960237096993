import useSelf from "@metronome/api/useSelf";
import { useParams } from "@tanstack/react-router";

export default function useWorkspaceId(): string {
	const params = useParams({
		experimental_returnIntersection: true,
		strict: false,
	});
	const workspaceId = params.workspaceId;
	const { data } = useSelf();
	return workspaceId ?? (data?.defaultWorkspaceId || "");
}
