import { useUpdateNodeReferences } from "@metronome/api/useMetadataDefinitions";
import { Button } from "@metronome/components/ui/button";

import type {
	IBusinessDimensionNode,
	INodeReference,
	INodeReferenceSpec,
} from "@metronome/types/BusinessDimension";
import type React from "react";
import { useMemo } from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";

type Props = {
	stepInstanceId: string;
	nodes: IBusinessDimensionNode[];
	nodeReferences: INodeReference[];
	referenceSpecs: INodeReferenceSpec[];
	onSuccess: () => void;
};

type FormValues = {
	nodeId: string;
	treeId: string;
	specId: string;
	refNodes: NodeReferenceLightModel[];
};

export type NodeReferenceLightModel = {
	referenceId: string | null;
	nodeId: string;
};

export const NodeReference: React.FC<Props> = ({
	stepInstanceId,
	nodes,
	nodeReferences,
	referenceSpecs,
	onSuccess,
}) => {
	const intl = useIntl();

	const { watch, control, setValue, handleSubmit } = useForm<FormValues>({
		shouldUnregister: true,
		defaultValues: {
			nodeId: "",
			treeId: "",
			specId: "",
			refNodes: [],
		},
		mode: "onBlur",
	});

	const nodeId = watch("nodeId");
	const treeId = watch("treeId");
	const specId = watch("specId");

	const { mutate: updateNodeReferences, isPending } =
		useUpdateNodeReferences(stepInstanceId);

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		updateNodeReferences({
			nodeId: data.nodeId,
			referenceSpecId: data.specId,
			nodeReferences: data.refNodes.filter((n) => n.nodeId !== ""),
		});
		onSuccess();
	};

	const nodeToSelect = useMemo(
		() =>
			nodes?.map(({ id, name }) => ({
				value: id,
				label: name,
			})),
		[nodes],
	);

	const specToSelect = useMemo(
		() =>
			referenceSpecs
				?.filter((spec) => spec.treeId === treeId)
				.map(({ id, referencedTreeName }) => ({
					value: id,
					label: referencedTreeName,
				})),
		[referenceSpecs, treeId],
	);

	const refNodesToSelect = useMemo(
		() =>
			referenceSpecs
				?.find((s) => s.id === specId)
				?.nodes.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
		[referenceSpecs, specId],
	);

	return (
		<>
			{intl.formatMessage({
				id: "NODE_REF.MODAL_TITLE",
			})}
			<form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="nodeId"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value, ref } }) => (
						<Select
							ref={ref}
							options={nodeToSelect}
							placeholder={intl.formatMessage({
								id: "NODE_REF.MODAL_CHOOSE_NODE",
							})}
							value={nodeToSelect.find((c) => value?.includes(c.value))}
							onChange={(val) => {
								onChange(val?.value);
								setValue(
									"treeId",
									nodes.find((n) => n.id === val?.value)?.tree.id ?? "",
								);
								if (specId) setValue("specId", "");
							}}
						/>
					)}
				/>

				{!!nodeId && !!treeId && (
					<Controller
						name="specId"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value, ref } }) => (
							<Select
								ref={ref}
								options={specToSelect}
								placeholder={intl.formatMessage({
									id: "NODE_REF.MODAL_CHOOSE_TREE",
								})}
								value={specToSelect?.find((c) => value?.includes(c.value))}
								onChange={(val) => {
									onChange(val?.value);
									const mappedReferences = nodeReferences
										.filter((n) => n.referencedSpecId === val?.value)
										.map((n) => ({
											referenceId: n.id,
											nodeId: n.referencedNode.id,
										}));
									setValue("refNodes", mappedReferences);
								}}
							/>
						)}
					/>
				)}

				{!!nodeId && !!specId && (
					<Controller
						name="refNodes"
						control={control}
						rules={{ required: false }}
						render={({ field: { onChange, value, ref } }) => (
							<Select
								ref={ref}
								options={refNodesToSelect}
								placeholder={intl.formatMessage({
									id: "NODE_REF.MODAL_CHOOSE_REFNODES",
								})}
								value={refNodesToSelect?.filter((c) =>
									value?.map((v) => v.nodeId)?.includes(c.value),
								)}
								maxMenuHeight={90}
								onChange={(val) => {
									onChange(
										val.map((c) => ({
											referenceId:
												value.find((v) => v.nodeId === c.value)?.referenceId ??
												null,
											nodeId: c.value,
										})),
									);
								}}
								isMulti
							/>
						)}
					/>
				)}
				<Button
					type="submit"
					variant="default"
					className="ms-auto"
					disabled={isPending}
				>
					{isPending ? (
						<FormattedMessage id="LOADING" />
					) : (
						<FormattedMessage id="UPDATE" />
					)}
				</Button>
			</form>
		</>
	);
};
