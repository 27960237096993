import { z } from "zod";
import { type FC, createContext, useContext } from "react";

const I18N_CONFIG_KEY = import.meta.env.VITE_I18N_CONFIG_KEY || "i18nConfig";

const Lang = z.union([z.literal("en"), z.literal("fr"), z.literal("xx")]);
export type Lang = z.infer<typeof Lang>;
const initialState: Lang = "en";

function getConfig(): Lang {
	const ls = localStorage.getItem(I18N_CONFIG_KEY) as Lang;

	const parse = Lang.safeParse(ls);
	if (!parse.success) {
		try {
			const parsedI18n = JSON.parse(ls) as {
				selectedLang: Lang;
			};
			localStorage.setItem(I18N_CONFIG_KEY, parsedI18n.selectedLang);
			return parsedI18n.selectedLang;
		} catch (er) {
			localStorage.setItem(I18N_CONFIG_KEY, "en");
			console.error(er);
			return "en";
		}
	}
	return ls ?? initialState;
}

// Side effect
export function setLanguage(lang: Lang): void {
	localStorage.setItem(I18N_CONFIG_KEY, lang);
	window.location.reload();
}

const I18nContext = createContext<Lang>(initialState);

const useLang = (): Lang => useContext(I18nContext);
type LanguageProviderProps = {
	children: React.ReactNode;
};

const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
	const lang = getConfig();
	return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { LanguageProvider, useLang };
