import {
	Combobox,
	ComboboxItem,
	ComboboxList,
	ComboboxProvider,
} from "@ariakit/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@metronome/utils";
import { getInitialsByName } from "@metronome/utils/formatText";
import { getUserUri } from "@metronome/utils/user";
import {
	CaretSortIcon,
	CheckIcon,
	MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import * as RadixSelect from "@radix-ui/react-select";
import { type FC, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";
import type {
	FetchNextPageOptions,
	InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import Avatar from "./Avatar";

export type ComboBoxItem = {
	value: string;
	label: string;
	image?: string;
};

type ComboboxPaginatedProps = {
	placeholder?: string;
	items?: Array<ComboBoxItem>;
	setSearchValue: (value: string) => void;
	setValue: (value: string) => void;
	value?: string;
	hasNextPage?: boolean;
	fetchNextPage: (
		option?: FetchNextPageOptions,
	) => Promise<InfiniteQueryObserverResult>;
};

export const ComboboxPaginated: FC<ComboboxPaginatedProps> = ({
	items,
	value,
	setValue,
	setSearchValue,
	fetchNextPage,
	hasNextPage,
	placeholder = "SEARCH",
}) => {
	const [open, setOpen] = useState(false);
	const { ref, inView } = useInView();
	const intl = useIntl();
	useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage]);

	return (
		<RadixSelect.Root
			value={value}
			onValueChange={setValue}
			open={open}
			onOpenChange={setOpen}
		>
			<ComboboxProvider
				open={open}
				setOpen={setOpen}
				resetValueOnHide
				includesBaseElement={false}
				setValue={(value) => {
					setSearchValue(value);
				}}
			>
				<RadixSelect.Trigger
					aria-label="Parties"
					className={cn(
						"flex h-8 w-full items-center justify-between rounded-md border border-input bg-white px-3 py-2 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
					)}
				>
					<RadixSelect.Value
						placeholder={intl.formatMessage({
							id: placeholder,
						})}
					/>
					<RadixSelect.Icon className="h-4 w-4 opacity-50">
						<CaretSortIcon />
					</RadixSelect.Icon>
				</RadixSelect.Trigger>
				<RadixSelect.Portal>
					<RadixSelect.Content
						role="dialog"
						position="popper"
						className={cn(
							"relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
							"data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
						)}
						sideOffset={4}
						alignOffset={-2}
					>
						<div className="sticky top-0 flex items-center justify-between px-1 pt-1 w-full">
							<div className="pointer-events-none absolute left-3 text-gray-800">
								<MagnifyingGlassIcon />
							</div>
							<Combobox
								autoSelect
								placeholder={intl.formatMessage({
									id: "SEARCH",
								})}
								className="z-50 mb-px relative h-10 appearance-none w-full rounded-md border border-input bg-slate-100 pr-2 pl-7 text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring"
								// Ariakit's Combobox manually triggers a blur event on virtually
								// blurred items, making them work as if they had actual DOM
								// focus. These blur events might happen after the corresponding
								// focus events in the capture phase, leading Radix Select to
								// close the popover. This happens because Radix Select relies on
								// the order of these captured events to discern if the focus was
								// outside the element. Since we don't have access to the
								// onInteractOutside prop in the Radix SelectContent component to
								// stop this behavior, we can turn off Ariakit's behavior here.
								onBlurCapture={(event) => {
									event.preventDefault();
									event.stopPropagation();
								}}
							/>
						</div>
						<RadixSelect.Viewport className="relative h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)] overflow-y-auto max-h-[10rem]">
							<ComboboxList className="overflow-y-auto p-1 relative">
								{items?.map((item) => (
									<RadixSelect.Item
										key={item.value}
										value={item.value}
										asChild
										className="relative flex w-full select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none cursor-pointer hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
									>
										<ComboboxItem>
											{!!item?.image && (
												<span className="pe-2">
													<Avatar
														src={getUserUri({ profilePictureUri: item?.image })}
														alt={getInitialsByName(item.label)}
													/>
												</span>
											)}
											<RadixSelect.ItemText>{item.label}</RadixSelect.ItemText>
											<span className="absolute right-2">
												<RadixSelect.ItemIndicator>
													<CheckIcon />
												</RadixSelect.ItemIndicator>
											</span>
										</ComboboxItem>
									</RadixSelect.Item>
								))}
								<div ref={ref} className="flex items-center justify-center p-4">
									{hasNextPage ? (
										<FontAwesomeIcon icon={["fas", "circle-dot"]} beatFade />
									) : null}
								</div>
							</ComboboxList>
						</RadixSelect.Viewport>
					</RadixSelect.Content>
				</RadixSelect.Portal>
			</ComboboxProvider>
		</RadixSelect.Root>
	);
};
