import type { EResolution } from "@metronome/types/Resolution";
import type React from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import {
	singleProcessOptions,
	useGates,
} from "@metronome/api/useProcessInstance";
import { ProgressBar } from "@metronome/components/Progress";
import { StageListSwitch } from "@metronome/components/StageList/StageListSwitch";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import type { ITimeline } from "@metronome/types/Timeline";
import { getHexColorFromStatusClass } from "@metronome/utils/status";
import { getTimelinessFromTimeline } from "@metronome/utils/timeliness";
import { WidthIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { FormattedNumber } from "react-intl";
import StepInstancePage from "../../../../../features/step-instance/StepInstancePage";
import styles from "./panel.module.scss";
import { createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { IGate } from "@metronome/types/Gate";

export const Route = createFileRoute(
	"/$workspaceId/process/$processId/gates-and-steps/$stepId",
)({
	loader: async ({ context, params }) => {
		const promises = [];
		promises.push(
			context.queryClient.ensureQueryData(
				singleProcessOptions(params.workspaceId, params.processId),
			),
		);
		await Promise.all(promises);
		return;
	},
	component: () => {
		const { workspaceId, processId, stepId } = Route.useParams();
		const { data: processInstance, isSuccess } = useSuspenseQuery(
			singleProcessOptions(workspaceId, processId),
		);
		const { data: gates } = useGates(processInstance?.id ?? "");
		if (isSuccess && processInstance && gates)
			return (
				<GatesTab
					processId={processId}
					stepId={stepId}
					resolution={processInstance.resolution}
					progress={processInstance?.progress ?? 0}
					timeline={processInstance.schedule}
					gates={gates}
				/>
			);
		return null;
	},
});

type GatesTabProps = {
	processId: string;
	stepId: string;
	progress: number;
	timeline?: ITimeline;
	resolution: EResolution;
	gates: IGate[];
};
export const GatesTab: React.FC<GatesTabProps> = ({
	processId,
	stepId,
	progress,
	timeline,
	resolution,
	gates,
}) => {
	const isMobile = useIsMobile();

	return (
		<div className="relative">
			<PanelGroup autoSaveId={processId} direction="horizontal">
				<Panel defaultSize={25} minSize={10} maxSize={40} collapsible={false}>
					<aside className="p-4 flex flex-row items-center bg-white gap-2 mw-75">
						<ProgressBar
							progress={progress}
							colorCouple={getHexColorFromStatusClass(
								resolution,
								getTimelinessFromTimeline(timeline),
							)}
						/>
						<FormattedNumber value={(progress ?? 0) / 100} style="percent" />
					</aside>
					<StageListSwitch gates={gates} />
				</Panel>
				{!isMobile && (
					<>
						<PanelResizeHandle
							className={clsx([styles.ResizeHandle, styles.ResizeHandleOuter])}
						>
							<div className={styles.ResizeHandleInner}>
								<WidthIcon className={styles.HorizontalIcon} />
							</div>
						</PanelResizeHandle>
						<Panel>
							<StepInstancePage isSideOpen stepId={stepId} />
						</Panel>
					</>
				)}
			</PanelGroup>
		</div>
	);
};
