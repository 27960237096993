import useMetadataDefinitions from "@metronome/api/useMetadataDefinitions";
import {
	ILightBusinessDimensionNode,
	IMetadataValue,
} from "@metronome/types/BusinessDimension";
import { Context } from "@metronome/types/Context";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { EditableMetadataValues } from "../MetadataList";
import { DisplayMetadataValue } from "../MetadataList/DisplayMetadataValues";
import styles from "./featuredMetadata.module.scss";

type FeaturedMetadataProps = {
	contextId: string;
	context: Context;
	businessDimensionNodes: ILightBusinessDimensionNode[];
};

export const FeaturedMetadata: React.FC<FeaturedMetadataProps> = ({
	contextId,
	context,
	businessDimensionNodes,
}) => {
	const uniqueNodeIds = [
		...new Set(businessDimensionNodes?.map((node) => node.id)),
	];

	const { data: metaDataDefinitions } = useMetadataDefinitions({
		nodeIds: uniqueNodeIds,
	});

	const featuredMetadataDefinitionsIds = useMemo(
		() =>
			metaDataDefinitions?.filter((def) => def.isFeatured).map((def) => def.id),
		[metaDataDefinitions],
	);

	const metadataValues = businessDimensionNodes.reduce(
		(acc, node) => {
			node.metadataValues.map((m) => {
				if (featuredMetadataDefinitionsIds?.includes(m.definition.id)) {
					acc.push({ nodeId: node.id, metadata: m });
				}
			});
			return acc;
		},
		[] as { nodeId: string; metadata: IMetadataValue }[],
	);

	if (metadataValues.length === 0) return null;

	return (
		<div>
			<strong>
				<FormattedMessage id="FEATURED_METADATA" />
			</strong>
			<div className={styles.gridContainerMetadata}>
				{metadataValues?.map((meta) => {
					const definition = metaDataDefinitions?.find(
						(def) => def.id === meta.metadata.definition.id,
					);
					if (!definition) return null;
					return definition.canBeEdited ? (
						<EditableMetadataValues
							nodeId={meta.nodeId}
							key={meta.metadata.id}
							contextId={contextId}
							context={context}
							definition={definition ?? []}
							values={meta.metadata}
						/>
					) : (
						<DisplayMetadataValue
							key={meta.metadata.id}
							metadataValue={meta.metadata}
							definition={definition}
						/>
					);
				})}
			</div>
		</div>
	);
};
