import { z } from "zod";

const NOT_STARTED = "notStarted";
const IN_PROGRESS = "inProgress";
const ON_HOLD = "onHold";
const DONE = "done";
const CANCELLED = "cancelled";

export const EResolution = z.enum([
	NOT_STARTED,
	IN_PROGRESS,
	ON_HOLD,
	DONE,
	CANCELLED,
]);

export type EResolution = z.infer<typeof EResolution>;

export const EEventResolution = z.enum([
	NOT_STARTED,
	IN_PROGRESS,
	DONE,
	CANCELLED,
]);

export type EEventResolution = z.infer<typeof EEventResolution>;

export const EMilestoneResolution = z.enum([NOT_STARTED, DONE, CANCELLED]);
export type EMilestoneResolution = z.infer<typeof EMilestoneResolution>;

export const ResolutionParam = z.object({
	resolution: EResolution,
	stepInstanceId: z.string().min(1),
	processInstanceId: z.string().min(1),
	isForced: z.boolean().optional(),
});

export type ResolutionParam = z.infer<typeof ResolutionParam>;
