import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelfUpdate } from "@metronome/api/useSelf";
import useWorkspaces from "@metronome/api/useWorkspaces";
import { Button } from "@metronome/components/ui/button";
import type { IWorkspace } from "@metronome/types/Workspace";
import type React from "react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

const NotFoundWorkspace = (): JSX.Element => (
	<>
		<h2>
			<FormattedMessage id="ERRORS.WORKSPACE.NOTFOUND.TITLE" />
		</h2>
		<div>
			<FormattedMessage id="ERRORS.WORKSPACE.NOTFOUND" />
		</div>
	</>
);

const UnauthorizedWorkspace = (): JSX.Element => (
	<>
		<h2>
			<FormattedMessage id="ERRORS.WORKSPACE.UNAUTHORIZED.TITLE" />
		</h2>
		<div>
			<FormattedMessage id="ERRORS.WORKSPACE.NOTFOUND" />
		</div>
	</>
);

const SingleWorkspacePreview: React.FC<{ workspace: IWorkspace }> = ({
	workspace,
}) => {
	const { mutate, isPending } = useSelfUpdate();
	const [isSelected, setIsSelected] = useState(false);

	const setDefaultUserWorkspace = (workspaceId: string): void => {
		mutate({
			defaultWorkspaceId: workspaceId,
		});
	};

	return (
		<div
			key={`workspace-${workspace.id}`}
			className="bg-white shadow-sm rounded-sm p-4 overflow-hidden"
		>
			<div className="flex flex-row justify-between items-start pb-4">
				<span className="font-bold text-lg">{workspace.name}</span>
				<div className="flex gap-1 items-center">
					<Button
						disabled={isPending}
						onClick={() => {
							setIsSelected(true);
							setDefaultUserWorkspace(workspace.id);
						}}
						variant="secondary"
					>
						{isPending && isSelected ? (
							<FormattedMessage id="LOADING" />
						) : (
							<FormattedMessage id="SELECT_WORKSPACE" />
						)}
					</Button>
					{isPending && isSelected ? (
						<FontAwesomeIcon
							className={"styles.icon"}
							spin
							icon={["fas", "spinner"]}
						/>
					) : null}
				</div>
			</div>
			<span className="uppercase text-xs font-bold">
				<FormattedMessage id="DESCRIPTION" />
			</span>
			<p className="mb-0">{workspace.description}</p>
		</div>
	);
};

const OnboardingPage: React.FC = () => {
	const { data: workspaces, isFetched } = useWorkspaces();
	const navigate = useNavigate({ from: "/onboarding" });

	const { from } = Route.useSearch();

	const is404 = from === "404";
	const is401 = from === "401";

	useEffect(() => {
		if (isFetched) {
			if (workspaces?.length === 0) {
				navigate({
					to: "/error/no-workspace",
				});
			}
		}
	}, [navigate, workspaces, isFetched]);

	const workspaceElements = workspaces?.map((workspace) => (
		<SingleWorkspacePreview key={workspace.id} workspace={workspace} />
	));

	return (
		<div>
			{is404 && <NotFoundWorkspace />}
			{is401 && <UnauthorizedWorkspace />}
			<div className="mt-8">
				<h2 className="text-2xl font-medium mb-4">
					<FormattedMessage id="PICK_A_WORKSPACE" />
				</h2>
				<div className="grid gap-2 grid-cols-auto-fit-300">
					{workspaceElements}
				</div>
			</div>
		</div>
	);
};

const OnboardingPageParams = v.object({
	from: v.optional(v.string()),
});

export const Route = createFileRoute("/onboarding")({
	component: OnboardingPage,
	validateSearch: (searchParams) => v.parse(OnboardingPageParams, searchParams),
});
