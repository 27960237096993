import { useGetNodesFiltersInfinite } from "@metronome/api/useFilters";
import type { IStepInstance } from "@metronome/types/StepInstance";
import type { Column } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { ComboboxPaginated } from "./ComboBoxPaginated";

type NodeFilterComponentProps = {
	column: Column<IStepInstance, unknown>;
};

type OptionType = {
	value: string;
	label: string;
};

export const NodeFilterComponent: React.FC<NodeFilterComponentProps> = ({
	column,
}) => {
	const [search, setSearch] = useState("");
	const {
		data: nodeFilters,
		fetchNextPage,
		hasNextPage,
	} = useGetNodesFiltersInfinite(search);

	const options: OptionType[] = useMemo(
		() =>
			nodeFilters?.pages
				.flatMap((page) => page?.results)
				.map(
					(modelRef) =>
						(({
                            value: modelRef?.id,
                            label: modelRef?.name
                        }) as OptionType),
				) ?? [],
		[nodeFilters],
	);

	const columnFilterValue = (column.getFilterValue() as string[]) ?? [];

	const intialValue = Array.isArray(columnFilterValue)
		? columnFilterValue
		: [columnFilterValue];

	const selectedFilters = options?.filter((option) =>
		intialValue.includes(option.value),
	);

	return (
		<div className={"mt-2 me-2"}>
			<ComboboxPaginated
				setSearchValue={setSearch}
				value={selectedFilters.length ? selectedFilters?.[0].value : ""}
				setValue={(id) => column.setFilterValue([id])}
				items={options}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
			/>
		</div>
	);
};
