import { gatesQueryOptions } from "@metronome/api/useProcessInstance";
import { getFirstNotDoneStepInstance } from "@metronome/utils/gatesUtils";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Navigate, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/$workspaceId/process/$processId/gates-and-steps/",
)({
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(
			gatesQueryOptions(params.workspaceId, params.processId),
		),
	component: () => {
		const { workspaceId, processId } = Route.useParams();
		const { data } = useSuspenseQuery(
			gatesQueryOptions(workspaceId, processId),
		);

		if (data) {
			const firstIdToNavigateTo = getFirstNotDoneStepInstance(data);
			if (firstIdToNavigateTo) {
				return (
					<Navigate
						to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
						params={{
							workspaceId,
							processId,
							stepId: firstIdToNavigateTo,
						}}
					/>
				);
			}
		}
	},
});
