import {
	IStepInstance,
	IStepTemplateFilter,
} from "@metronome/types/StepInstance";
import { groupBy } from "@metronome/utils/arrayUtils";
import { Column } from "@tanstack/react-table";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import Button from "./Button";

type StepFilterComponentProps = {
	column: Column<IStepInstance, unknown>;
};

type OptionType = {
	value: string;
	label: string;
};

export const StepFilterComponent: React.FC<StepFilterComponentProps> = ({
	column,
}) => {
	const columnFilterValue = column.getFilterValue();
	const groupedOptions =
		column.columnDef?.meta?.stepFilters
			?.filter((v) => !!v)
			.map((modelRef: IStepTemplateFilter) => ({
				value: modelRef.ids.join(","),
				label: modelRef.name,
			})) ?? [];

	const value = Array.isArray(columnFilterValue)
		? columnFilterValue
		: [columnFilterValue];

	const initalStepTemplates = groupedOptions
		?.flatMap((option: OptionType) =>
			option.value.split(",").map((v) => ({
				stepId: v,
				stepName: option.label,
			})),
		)
		.filter((option) => value.includes(option.stepId));

	const selectedFilters = Object.entries(
		groupBy(initalStepTemplates, (stepTemplate) => stepTemplate.stepName),
	).map((kvp) => ({
		label: kvp[0],
		value: kvp[1].map((stepTemplate) => stepTemplate.stepId).join(","),
	}));

	return (
		<div className="mt-2 me-2 mb-24">
			<Select
				value={selectedFilters}
				maxMenuHeight={90}
				onChange={(val) => {
					column.setFilterValue(val?.flatMap((v) => v.value.split(",")));
				}}
				isMulti
				options={groupedOptions}
			/>
			{selectedFilters && selectedFilters?.length > 0 && (
				<div className="flex flex-row justify-between mt-2">
					{selectedFilters.length === 1 ? (
						<>
							{selectedFilters.length} <FormattedMessage id="FILTER_APPLIED" />
						</>
					) : (
						<>
							{selectedFilters.length} <FormattedMessage id="FILTERS_APPLIED" />
						</>
					)}
					<Button
						appearance="default"
						onClick={() => {
							column.setFilterValue(undefined);
						}}
					>
						<FormattedMessage id="CLEAR_FILTERS" />
					</Button>
				</div>
			)}
		</div>
	);
};
