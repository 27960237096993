import { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import { EditableCell } from "@metronome/components/EditableCell";
import { Lozenge } from "@metronome/components/Lozenge";
import { Context } from "@metronome/types/Context";
import { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { IProcessInstance } from "@metronome/types/ProcessInstance";
import { IStepInstance } from "@metronome/types/StepInstance";
import { getInputType } from "@metronome/utils/metadataType";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MultiSelectMetadata } from "../EditSingleMetadata";

type AddSingleMetadataProps = {
	instance: IStepInstance | IProcessInstance;
	context: Context;
	metadataDefinition: IMetadataDefinition;
};

export const AddSingleMetadata: React.FC<AddSingleMetadataProps> = ({
	instance,
	context,
	metadataDefinition,
}) => {
	const { mutate: mutateNodeName } = useUpsertMetadataValue(
		instance.id,
		context,
	);

	const getBusinessDimensions = instance.businessDimensions?.find(
		(dimension) => dimension.tree.id === metadataDefinition.tree.id,
	);

	if (!getBusinessDimensions?.id) {
		return (
			<Lozenge appearance="default">
				<FormattedMessage id="NOT_RELATED" />
			</Lozenge>
		);
	}

	// the metadata has been restricted to some NodeTypes
	if (metadataDefinition.restrictedNodeTypes.length) {
		const nodeTypesMatch = instance.businessDimensions?.some((bd) =>
			metadataDefinition.restrictedNodeTypes.find(
				(rNodeTypes) => rNodeTypes?.id === bd.nodeType?.id,
			),
		);

		if (!nodeTypesMatch) {
			return (
				<Lozenge appearance="default">
					<FormattedMessage id="RESTRICTED" />
				</Lozenge>
			);
		}
	}

	if (metadataDefinition.type === "singleSelect") {
		return (
			<select
				className="w-full"
				onChange={(e) => {
					if (getBusinessDimensions?.id) {
						mutateNodeName({
							definitionId: metadataDefinition.id,
							nodeId: getBusinessDimensions.id,
							metadataValues: [
								{
									value: e.target.value,
								},
							],
						});
					}
				}}
			>
				<option key="empty" />
				{metadataDefinition.enum?.map((v) => (
					<option key={v} value={v}>
						{v}
					</option>
				))}
			</select>
		);
	}

	if (metadataDefinition.type === "multiSelect") {
		if (metadataDefinition.enum) {
			return (
				<MultiSelectMetadata
					metaData={undefined}
					businessDimensions={getBusinessDimensions}
					metadataDefinition={metadataDefinition}
					mutateNodeName={mutateNodeName}
				/>
			);
		}
	}

	return (
		<EditableCell
			value=""
			type={getInputType(metadataDefinition.type)}
			onValidate={(v: string) => {
				mutateNodeName({
					definitionId: metadataDefinition.id,
					nodeId: getBusinessDimensions.id,
					metadataValues: [{ value: v }],
				});
			}}
		/>
	);
};
