import { z } from "zod";
import { IIndicator } from "./Indicator";
import { IModelReference } from "./ModelReference";
import { IProcessTemplate } from "./ProcessTemplate";
import { ThirdApps } from "./ThirdApp";
import { ETimeliness } from "./Timeliness";

export const IRoleRequirement = z.object({
	roleName: z.string(),
	roleId: z.string(),
});
export type IRoleRequirement = z.infer<typeof IRoleRequirement>;

export const IRoleRequirements = z.object({
	accountableRoles: z.array(IRoleRequirement),
	responsibleRoles: z.array(IRoleRequirement),
	consultedRoles: z.array(IRoleRequirement),
	informedRoles: z.array(IRoleRequirement),
});
export type IRoleRequirements = z.infer<typeof IRoleRequirements>;

export const IStreamContext = z.object({
	tree: IModelReference,
	level: IModelReference.optional(),
});

export const IFilteringValueLabel = IModelReference.extend({
	operation: z.string(),
});
export type IFilteringValueLabel = z.infer<typeof IFilteringValueLabel>;

const IFilteringValue = z.object({
	labels: z.array(IFilteringValueLabel),
	level: IModelReference.optional(),
	node: IModelReference,
});

export const IFiltering = z.object({
	method: z.enum(["Span", "Filter"]),
	values: IFilteringValue,
});
export type IFiltering = z.infer<typeof IFiltering>;

export const IProcessStream = z.object({
	id: z.string(),
	name: z.string(),
	isActive: z.boolean(),
	organization: IModelReference,
	activeProcessTemplate: IProcessTemplate.optional(),
	indicators: z.array(IIndicator),
	roleRequirements: IRoleRequirements.optional(),
	recurrence: z.string(),
	filtering: z.array(IFiltering).optional(),
	timeliness: ETimeliness,
	closeOnCompletion: z.boolean(),
	autoClose: z.number(),
	category: IModelReference,
	pictureUri: z.string().optional(),
	origins: z.array(ThirdApps).optional(),
	timeZone: z.string(),
});
export type IProcessStream = z.infer<typeof IProcessStream>;
