import { formatDuration } from "date-fns/formatDuration";
import { parse } from "duration-fns/dist/parse";

export function durationToString(durationString: string): string {
	return durationString === "0:00:00" ? "" : durationString;
}

export function formatDurationToString(
	durationString: string | undefined,
): string {
	if (!durationString || durationString === "P") return "N/A";
	return formatDuration(parse(durationString));
}
