import { RACIProvider } from "@metronome/context/RACIData";
import { YourWorkTemplate } from "@metronome/features/YourWorkTemplate";
import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/$workspaceId/your-work")({
	component: () => (
		<RACIProvider>
			<YourWorkTemplate />
			<Outlet />
		</RACIProvider>
	),
});
