import { useOrganizationData } from "@metronome/context/OrganizationData";
import React from "react";

type Props = {
	organizationId: string;
};

export const OrganizationName: React.FC<Props> = ({ organizationId }) => {
	const { organizations } = useOrganizationData();
	const organization = organizations?.find(
		(orga) => orga.id === organizationId,
	);
	return <span>{organization?.name}</span>;
};
