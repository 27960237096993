import {
	ChatBubbleIcon,
	CounterClockwiseClockIcon,
	LapTimerIcon,
	ListBulletIcon,
} from "@radix-ui/react-icons";
import type React from "react";
import { useIntl } from "react-intl";
import { Link } from "@tanstack/react-router";

import { Card } from "@metronome/components/LegacyCard/Card";
import { CardBody } from "@metronome/components/LegacyCard/CardBody";
import { CardHeader } from "@metronome/components/LegacyCard/CardHeader";
import { BreadcrumbsResponsive } from "@metronome/components/MultiLevelBreadcrumbs";
import type {
	ILinkMultiLevel,
	ILinkMultiLevelProcessInstance,
} from "@metronome/types/Link";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";

export const ProcessInstanceTemplate: React.FC<{
	processInstance: IProcessInstance;
	children: React.ReactNode;
}> = ({ processInstance, children }) => {
	const intl = useIntl();
	const workspaceId = useWorkspaceId();

	const params = {
		workspaceId,
		processId: processInstance.id,
	};

	const breadcrumbsLinks: Array<
		ILinkMultiLevel | ILinkMultiLevelProcessInstance
	> = [
		{
			active: false,
			type: "PROCESS_STREAM",
			name: processInstance.processStream.name,
			href: "/$workspaceId/stream/$streamId",
			params: {
				workspaceId,
				streamId: processInstance.processStream.id,
			},
		},
		{
			active: true,
			ressourceId: processInstance.id,
			type: "PROCESS_INSTANCE",
			name: processInstance.name,
			href: "#",
			params: {},
		},
	];

	return (
		<div className="flex flex-col gap-4 overflow-hidden">
			<BreadcrumbsResponsive links={breadcrumbsLinks} />
			<Card>
				<CardHeader className="hidden md:block">
					<ul className="flex flex-row my-2">
						<li className="rounded">
							<Link
								to="/$workspaceId/process/$processId/gates-and-steps"
								params={params}
								className="font-medium px-5 py-2 rounded transition-colors"
								activeProps={{
									className: "text-primary",
								}}
							>
								<ListBulletIcon className="inline-block me-1 [vertical-align:-0.2em]" />
								{intl.formatMessage({
									id: "PROCESS_INSTANCE.TABS.GATES_AND_STEPS",
								})}
							</Link>
						</li>
						<li className="nav-item">
							<Link
								to="/$workspaceId/process/$processId/notes"
								params={params}
								className="font-medium px-5 py-2 rounded transition-colors"
								activeProps={{
									className: "text-primary",
								}}
							>
								<ChatBubbleIcon className="inline-block me-1 [vertical-align:-0.125em]" />
								{intl.formatMessage({ id: "PROCESS_INSTANCE.TABS.NOTES" })}
							</Link>
						</li>
						<li className="">
							<Link
								to="/$workspaceId/process/$processId/attachments"
								className="font-medium px-5 py-2 rounded transition-colors"
								params={params}
								activeProps={{
									className: "text-primary",
								}}
							>
								<svg
									className="inline-block me-1 [vertical-align:-0.125em]"
									width="15"
									height="15"
									viewBox="0 0 15 15"
									fill="#000000"
								>
									<title>attachment</title>
									<path d="M12.6429 7.69048L8.92925 11.4041C7.48164 12.8517 5.34347 13.0101 4.16667 11.8333C2.98733 10.654 3.14447 8.52219 4.59216 7.07451L8.00206 3.66461C8.93557 2.73109 10.2976 2.63095 11.0333 3.36667C11.7681 4.10139 11.6658 5.4675 10.7361 6.39727L7.32363 9.8097C6.90202 10.2313 6.32171 10.2741 6.02381 9.97619C5.72651 9.6789 5.76949 9.09718 6.1989 8.66776L9.29048 5.57619C9.56662 5.30005 9.56662 4.85233 9.29048 4.57619C9.01433 4.30005 8.56662 4.30005 8.29048 4.57619L5.1989 7.66776C4.24737 8.6193 4.13865 10.091 5.02381 10.9762C5.9095 11.8619 7.37984 11.7535 8.32363 10.8097L11.7361 7.39727C13.1876 5.94573 13.3564 3.68975 12.0333 2.36667C10.7099 1.04326 8.45782 1.20884 7.00206 2.66461L3.59216 6.07451C1.62229 8.04437 1.39955 11.0662 3.16667 12.8333C4.93146 14.5981 7.9596 14.3737 9.92925 12.4041L13.6429 8.69048C13.919 8.41433 13.919 7.96662 13.6429 7.69048C13.3667 7.41433 12.919 7.41433 12.6429 7.69048Z" />
								</svg>
								{intl.formatMessage({
									id: "PROCESS_INSTANCE.TABS.ATTACHMENTS",
								})}
							</Link>
						</li>
						<li className="">
							<Link
								to="/$workspaceId/process/$processId/history"
								params={params}
								className="font-medium px-5 py-2 rounded transition-colors"
								activeProps={{
									className: "text-primary",
								}}
							>
								<CounterClockwiseClockIcon className="inline-block me-1 [vertical-align:-0.125em]" />
								{intl.formatMessage({
									id: "TABS.HISTORY",
								})}
							</Link>
						</li>
						<li className="">
							<Link
								to="/$workspaceId/process/$processId/timeline"
								params={params}
								className="font-medium px-5 py-2 rounded transition-colors"
								activeProps={{
									className: "text-primary",
								}}
							>
								<LapTimerIcon className="inline-block me-1 [vertical-align:-0.125em]" />
								{intl.formatMessage({
									id: "TABS.TIMELINE",
								})}
							</Link>
						</li>
					</ul>
				</CardHeader>
				<CardBody>{children}</CardBody>
			</Card>
		</div>
	);
};
