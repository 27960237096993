import clsx from "clsx";
import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { APPEARANCES } from "@metronome/constants/theme";
import styles from "./dropdown.module.scss";

type Actions = Array<{
	name: string;
	disabled?: boolean;
	onSelect: () => void;
}>;

export const Dropdown: React.FC<{
	actions: Actions;
	trigger: string | JSX.Element;
	appearance: APPEARANCES;
}> = ({ actions, trigger, appearance }): JSX.Element => (
	<>
		<style>{`.${styles.dropdown_trigger} {--color: ${
			appearance === "default" ? "var(--text-default)" : "var(--text-success)"
		}}`}</style>
		<DropdownMenuPrimitive.Root>
			<DropdownMenuPrimitive.Trigger className={styles.dropdown_trigger}>
				{trigger}
				<FontAwesomeIcon icon={["fas", "caret-down"]} />
			</DropdownMenuPrimitive.Trigger>
			<DropdownMenuPrimitive.Content
				align="end"
				className={styles.dropdown_content}
			>
				{actions.map((action) => (
					<DropdownMenuPrimitive.Item
						key={action.name}
						className={clsx({
							[styles.itemStyle]: true,
							[styles.itemStyleDisabled]: action.disabled,
						})}
						onSelect={action.onSelect}
					>
						{action.name}
					</DropdownMenuPrimitive.Item>
				))}
			</DropdownMenuPrimitive.Content>
		</DropdownMenuPrimitive.Root>
	</>
);
export default Dropdown;

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export const DropdownMenuContent = React.forwardRef<
	HTMLDivElement,
	DropdownMenuPrimitive.DropdownMenuContentProps
>(({ children, ...props }, forwardedRef) => (
	<DropdownMenuPrimitive.Portal>
		<DropdownMenuPrimitive.Content
			{...props}
			ref={forwardedRef}
			className={styles.dropdown_content}
		>
			{children}
			<DropdownMenuPrimitive.Arrow className={styles.dropdownMenuArrow} />
		</DropdownMenuPrimitive.Content>
	</DropdownMenuPrimitive.Portal>
));

DropdownMenuContent.displayName = "DropdownMenuContent";

export const DropdownMenuLabel = DropdownMenuPrimitive.Label;
export const DropdownMenuItem = DropdownMenuPrimitive.Item;
export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuCheckboxItem = React.forwardRef<
	HTMLDivElement,
	DropdownMenuPrimitive.DropdownMenuCheckboxItemProps
>(({ children, ...props }, forwardedRef) => (
	<DropdownMenuPrimitive.CheckboxItem {...props} ref={forwardedRef}>
		{children}
		<DropdownMenuPrimitive.ItemIndicator>
			{props.checked === "indeterminate" && (
				<FontAwesomeIcon icon={["fas", "divide"]} />
			)}
			{props.checked === true && "-"}
		</DropdownMenuPrimitive.ItemIndicator>
	</DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = "DropdownMenuCheckboxItem";

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const DropdownMenuRadioItem = React.forwardRef<
	HTMLDivElement,
	DropdownMenuPrimitive.DropdownMenuRadioItemProps
>(({ children, ...props }, forwardedRef) => (
	<DropdownMenuPrimitive.RadioItem {...props} ref={forwardedRef}>
		{children}
		<DropdownMenuPrimitive.ItemIndicator>
			<FontAwesomeIcon icon={["fas", "circle-check"]} />
		</DropdownMenuPrimitive.ItemIndicator>
	</DropdownMenuPrimitive.RadioItem>
));

DropdownMenuRadioItem.displayName = "DropdownMenuRadioItem";

export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
