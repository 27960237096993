import type { IParty } from "@metronome/types/Party";
import type { IUser } from "@metronome/types/User";
import { useIntl } from "react-intl";

export function getResponsibleUser(
	responsibleAssignees: IParty[],
	me?: IUser,
): IParty | undefined {
	const iAmresponsible = responsibleAssignees.find((u) => u?.userId === me?.id);
	if (iAmresponsible) return iAmresponsible;
	if (responsibleAssignees.length) return responsibleAssignees[0];
	return undefined;
}

export function getUserUri(
	user: { profilePictureUri?: string } | undefined,
): string | undefined {
	return !user?.profilePictureUri
		? undefined
		: `${window.VITE_API_URL || import.meta.env.VITE_API_URL}/${
				user.profilePictureUri
			}`;
}

export function getAvatar(
	profilePictureUri: string | undefined,
): string | undefined {
	return !profilePictureUri
		? undefined
		: `${
				window.VITE_API_URL || import.meta.env.VITE_API_URL
			}/${profilePictureUri}`;
}

export function getUserInfoUri(userInfo?: IParty): string | undefined {
	return !userInfo?.profilePictureUri
		? undefined
		: `${window.VITE_API_URL || import.meta.env.VITE_API_URL}/${
				userInfo.profilePictureUri
			}`;
}

type MaybeUserOrParty = IParty | IUser | undefined;

export function displayRoles(user: MaybeUserOrParty): string {
	return useIntl().formatList(
		user?.roles
			?.slice(0, user.roles.length > 3 ? 3 : user.roles.length)
			?.map((r) => r.name) ?? [""],
		{
			style: "long",
			type: "conjunction",
		},
	);
}

export function useName(user: MaybeUserOrParty): string {
	const { locale } = useIntl();
	return `${user?.lastName?.toLocaleUpperCase(locale) ?? ""} ${
		user?.firstName ?? ""
	}`;
}
