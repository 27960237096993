import {
	EStepInstanceType,
	IStepInstance,
} from "@metronome/types/StepInstance";
import { Column } from "@tanstack/react-table";
import { FormattedMessage, useIntl } from "react-intl";

import {
	ToggleGroup,
	ToggleGroupItem,
} from "@metronome/components/ui/toggle-group";
import { StepInstanceIcon } from "./IconStepInstance";

type TypeFilterComponentProps = {
	column: Column<IStepInstance, unknown>;
};

export const TypeFilterComponent: React.FC<TypeFilterComponentProps> = ({
	column,
}) => {
	const intl = useIntl();
	const columnFilterValue = (column.getFilterValue() as string[]) ?? [];
	const onUpdateFilter = (filter: string[]) => {
		column.setFilterValue(filter);
	};
	return (
		<div>
			<strong>
				<FormattedMessage id="FILTER_BY_TYPE" />
			</strong>
			<ToggleGroup
				value={columnFilterValue}
				onValueChange={onUpdateFilter}
				type="multiple"
				className="mt-2 justify-start gap-2"
			>
				{EStepInstanceType.options
					.filter((option) => option !== "hook")
					.map((type) => (
						<ToggleGroupItem
							className="gap-2 data-[state=on]:text-primary"
							key={type}
							value={type}
						>
							<StepInstanceIcon type={type} />{" "}
							<FormattedMessage id={`STEP_INSTANCE.TYPE.${type}`} />
						</ToggleGroupItem>
					))}
			</ToggleGroup>
			<div className="mt-2">
				{!!columnFilterValue.length &&
					`${columnFilterValue.length} ${intl.formatMessage({
						id:
							columnFilterValue.length > 1
								? "FILTERS_APPLIED"
								: "FILTER_APPLIED",
					})}`}
			</div>
		</div>
	);
};
