import type React from "react";
import { useIntl } from "react-intl";

import { useOrganizationData } from "@metronome/context/OrganizationData";

export const SwitchOrganizationNative: React.FC = () => {
	const intl = useIntl();
	const { organizations, activeOrganization, setActiveOrganization } =
		useOrganizationData();

	if (organizations?.length === 1) return null;

	return (
		<div className="relative">
			<select
				value={activeOrganization ?? organizations?.[0]?.id}
				onChange={(event) => {
					setActiveOrganization(event.target.value);
				}}
			>
				<option>
					{organizations?.find((orga) => orga.id === activeOrganization)
						?.name ?? intl.formatMessage({ id: "YOUR_WORK.ALL_ORGANIZATIONS" })}
				</option>
				{organizations?.map((organization) => (
					<option key={organization.id} value={organization.id ?? ""}>
						{organization.name ?? ""}
					</option>
				))}
			</select>
		</div>
	);
};

export default SwitchOrganizationNative;
