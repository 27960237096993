import React, { type SetStateAction, useMemo, useState } from "react";

// write a context provider for the RACI selection

type RACIModel = {
	RACI: string[];
	setRACI: React.Dispatch<SetStateAction<string[]>>;
};

const RACIContext = React.createContext<RACIModel>({
	RACI: [],
	setRACI: () => null,
});

export default RACIContext;

type Props = {
	children: React.ReactNode;
};

// type RACIModel = ["R", "A", "C", "I"] as const;

export const RACIProvider: React.FC<Props> = ({ children }) => {
	const [RACI, setRACI] = useState(["R"]);

	const value = useMemo(
		() => ({
			RACI,
			setRACI,
		}),
		[RACI],
	);

	return <RACIContext.Provider value={value}>{children}</RACIContext.Provider>;
};

export const useRACIData = () => React.useContext(RACIContext);
