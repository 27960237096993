import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimeCell from "@metronome/components/DateCell";
import DisplayDoerType from "@metronome/components/DisplayDoerType";
import { Lozenge } from "@metronome/components/Lozenge";
import { getDoerName } from "@metronome/types/Doer";
import {
	EActionType,
	EValueType,
	type IEntityHistory,
	type IValue,
} from "@metronome/types/History";
import type { ProcessState } from "@metronome/types/ProcessInstance";
import type { EResolution } from "@metronome/types/Resolution";
import type { IStepInstance } from "@metronome/types/StepInstance";
import formatDate, {
	formatDateDistanceToNowStrict,
} from "@metronome/utils/formatDate";
import {
	getResolutionApperance,
	getStateApperance,
} from "@metronome/utils/status";
import type React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import sanitizeHtml from "sanitize-html";
import styles from "./HistoryTemplate.module.scss";

const getActionElements = (
	actionType: EActionType,
): JSX.Element | undefined => {
	switch (actionType) {
		case EActionType.flagAdded:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.FLAG_ADDED" />
					<FontAwesomeIcon
						icon={["fas", "flag"]}
						style={{ color: "var(--text-danger)" }}
					/>
				</span>
			);
		case EActionType.flagRemoved:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.FLAG_REMOVED" />
					<FontAwesomeIcon icon={["fas", "flag"]} color="grey" />
				</span>
			);
		case EActionType.resolutionChanged:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.RESOLUTION_CHANGED" />
				</span>
			);
		case EActionType.effectiveDateChanged:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.EFFECTIVE_CHANGED" />
				</span>
			);
		case EActionType.startDateChanged:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.STARTDATE_EDITED" />
				</span>
			);
		case EActionType.stateChanged:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.STATE_EDITED" />
				</span>
			);
		case EActionType.noteAdded:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.NOTE_ADDED" />
				</span>
			);
		case EActionType.noteEdited:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.NOTE_EDITED" />
				</span>
			);
		case EActionType.attachmentAdded:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.ATTACHMENT_ADDED" />
				</span>
			);
		case EActionType.attachmentEdited:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.ATTACHMENT_EDITED" />
				</span>
			);
		case EActionType.formAdded:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.FORM_ADDED" />
				</span>
			);
		case EActionType.formEdited:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.FORM_EDITED" />
				</span>
			);
		case EActionType.agendaCompleted:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.AGENDA_COMPLETE" />
				</span>
			);
		case EActionType.agendaUncompleted:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.AGENDA_INCOMPLETE" />
				</span>
			);
		case EActionType.dueDateChanged:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.STARTDATE_EDITED" />
				</span>
			);
		case EActionType.notLaterThanChanged:
			return (
				<span className={styles.element}>
					<FormattedMessage id="HISTORY.DEADLINE_EDITED" />
				</span>
			);
		default:
			return undefined;
	}
};

const getValueTypeElements = (
	value: IValue | undefined,
	stepInstanceType?: IStepInstance["type"],
): JSX.Element | undefined => {
	switch (value?.type) {
		case EValueType.resolution: {
			const appearance = getResolutionApperance(
				value.value as unknown as EResolution,
			);
			return (
				<Lozenge appearance={appearance}>
					<FormattedMessage
						id={`RESOLUTION.${stepInstanceType}.${value.value}`}
					/>
				</Lozenge>
			);
		}
		case EValueType.text: {
			const text = value.value ? (
				sanitizeHtml(`${value.value}`, { allowedTags: ["span"] })
			) : (
				<FormattedMessage id="NO_VALUE" />
			);
			return (
				<p
					className="truncate"
					title={typeof text === "string" ? text : ""}
					style={{ maxWidth: "200px" }}
				>
					{text}
				</p>
			);
		}
		case EValueType.state: {
			const appearance = getStateApperance(
				value.value as unknown as ProcessState,
			);
			return <Lozenge appearance={appearance}>{value.value}</Lozenge>;
		}
		case EValueType.date: {
			return <span>{value.value}</span>;
		}
		case EValueType.dateTime: {
			return (
				<DateTimeCell
					datetime={value.value.toString()}
					displayTime={value.setByUser}
				/>
			);
		}

		default:
			return undefined;
	}
};

export const HistoryTemplate: React.FC<{
	histories?: IEntityHistory[];
	stepInstanceType?: IStepInstance["type"];
}> = ({ histories, stepInstanceType }) => {
	const intl = useIntl();

	return (
		<div className="m-2">
			{histories?.map((history) => (
				<div key={`history-${history.id}`} className={styles.historyContainer}>
					<DisplayDoerType doer={history.performedBy} />
					<div>
						<div>
							<b>{getDoerName(history.performedBy)}</b>
							{getActionElements(history.actionType)}
							<span>
								<b>
									{history.reference?.name}
									{"  "}
								</b>
							</span>
							<span
								className="text-slate-600"
								title={formatDate(history.performedAt, "PPpp", {
									locale: intl.locale,
								})}
							>
								{formatDateDistanceToNowStrict(new Date(history.performedAt), {
									addSuffix: true,
									locale: intl.locale,
								})}
							</span>
						</div>
						{history.previousValue ? (
							<div className={styles.valueContainer}>
								{getValueTypeElements(history.previousValue, stepInstanceType)}{" "}
								{"  "}
								<FontAwesomeIcon
									icon={["fas", "arrow-right"]}
									className="px-2"
								/>{" "}
								{"  "}
								{getValueTypeElements(history.currentValue, stepInstanceType)}
							</div>
						) : undefined}
					</div>
				</div>
			))}
		</div>
	);
};
