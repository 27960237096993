import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import { format } from "date-fns/format";
import { format as formatTz } from "date-fns-tz";
import { isValid } from "date-fns/isValid";
import type { IntlShape } from "react-intl";

import { getHours } from "date-fns/getHours";
import { getMinutes } from "date-fns/getMinutes";
import { fr } from "date-fns/locale/fr";
import { enGB as en } from "date-fns/locale/en-GB";
import type { Locale } from "date-fns/types";

const locales = { fr, en };

const formatDate = "PP";

function isValidDateObject(date: Date | string): date is Date {
	return date instanceof Date;
}

function extractHoursAndMinutes(dateRaw: Date): {
	hours: number;
	minutes: number;
} {
	return {
		hours: getHours(dateRaw),
		minutes: getMinutes(dateRaw),
	};
}

function formatWithZonedTime(
	dateSplitted: string[],
	intl: IntlShape,
): { time: string; date: string } {
	const dateRaw = zonedTimeToUtc(dateSplitted[0], dateSplitted[1]);

	const { hours, minutes } = extractHoursAndMinutes(dateRaw);

	return {
		time:
			hours === 0 && minutes === 0
				? ""
				: format(dateRaw, "p", {
						locale: locales[intl.locale as keyof typeof locales] as Locale,
					}),
		date: format(dateRaw, formatDate, {
			locale: locales[intl.locale as keyof typeof locales] as Locale,
		}),
	};
}

function formatWithoutZonedTime(
	dateString: string,
	intl: IntlShape,
): { time: string; date: string } {
	try {
		const dateRaw = new Date(dateString);
		const { hours, minutes } = extractHoursAndMinutes(dateRaw);

		return {
			time:
				hours === 0 && minutes === 0
					? ""
					: format(dateRaw, "p", {
							locale: locales[intl.locale as keyof typeof locales] as Locale,
						}),
			date: format(dateRaw, formatDate, {
				locale: locales[intl.locale as keyof typeof locales] as Locale,
			}),
		};
	} catch {
		return { time: "null", date: "null" };
	}
}

export function formatObjectDate(
	date: Date,
	intl: IntlShape,
): { time: string; date: string } {
	const { hours, minutes } = extractHoursAndMinutes(date);
	return {
		time:
			hours === 0 && minutes === 0
				? ""
				: format(date, "p", {
						locale: locales[intl.locale as keyof typeof locales] as Locale,
					}),
		date: format(date, formatDate, {
			locale: locales[intl.locale as keyof typeof locales] as Locale,
		}),
	};
}

export function formatTimeZoneToDateString(
	dateString: string,
	intl: IntlShape,
): { time: string; date: string } {
	if (!dateString) return { time: "", date: "" };
	if (isValidDateObject(dateString)) return formatObjectDate(dateString, intl);
	if (isValid(dateString)) return formatWithoutZonedTime(dateString, intl);
	const dateSplitted = dateString.split(" ");
	if (dateSplitted.length === 2) return formatWithZonedTime(dateSplitted, intl);
	return formatWithoutZonedTime(dateString, intl);
}

export function formatTimestampToDateString(
	timestamp: number | undefined,
	intl: IntlShape,
): { time: string; date: string } {
	if (!timestamp) return { time: "", date: "" };
	return formatObjectDate(new Date(timestamp), intl);
}

export function atMidnight(date: string | undefined): Date | undefined {
	let possiblyDateObject: Date | undefined;
	try {
		possiblyDateObject = new Date(`${date}T00:00:00`);
	} catch {
		possiblyDateObject = undefined;
	}
	return possiblyDateObject;
}

export function atEndOfDay(date: string | undefined): Date | undefined {
	if (!date) return undefined;
	const midnight = atMidnight(date);
	if (!midnight) return undefined;
	// here midnight is already a date object or the function would have returned at the line above
	return new Date(midnight.getTime() + 86400000 - 1); // day in ms - 1 ms
}

export function formatOffsetDateTime(date: Date) {
	return formatTz(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	});
}
