import React from "react";
import { HexColorCouple } from "@metronome/utils/status";
import * as Progress from "@radix-ui/react-progress";
import styles from "./style.module.scss";

type Props = {
	progress: number;
	colorCouple: HexColorCouple;
};

export const ProgressBar: React.FC<Props> = ({ progress = 0, colorCouple }) => {
	return (
		<Progress.Root
			max={100}
			className={styles.ProgressRoot}
			style={{ backgroundColor: colorCouple.background }}
			value={progress}
		>
			<Progress.Indicator
				className={styles.ProgressIndicator}
				style={{
					transform: `translateX(-${100 - progress}%)`,
					backgroundColor: colorCouple.foreground,
				}}
			/>
		</Progress.Root>
	);
};
