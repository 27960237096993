import type {
	ISchedule,
	IStepInstance,
	StepInstanceCompletion,
} from "@metronome/types/StepInstance";
import type { ITimeline } from "@metronome/types/Timeline";
import { EStepTimeliness, ETimeliness } from "@metronome/types/Timeliness";

export function getTimelinessFromStepInstance(
	schedule: ISchedule,
	completion?: StepInstanceCompletion,
	type?: IStepInstance["type"],
): EStepTimeliness | undefined {
	if (!schedule.deadline) return;

	const timestampDeadline = Date.parse(
		type === "milestone" ? schedule.targetStart : schedule.deadline,
	);
	if (Number.isNaN(timestampDeadline)) return;

	if (completion?.performedAt) {
		if (completion.performedAt > timestampDeadline)
			return EStepTimeliness.enum.late;
		return EStepTimeliness.enum.onTrack;
	}

	if (schedule?.resolutionDate) {
		if (Date.parse(schedule.resolutionDate) > timestampDeadline)
			return EStepTimeliness.enum.late;
		return EStepTimeliness.enum.onTrack;
	}

	if (Date.now() > timestampDeadline) return EStepTimeliness.enum.late;

	return EStepTimeliness.enum.onTrack;
}

export function getTimelinessFromTimeline(timeline?: ITimeline): ETimeliness {
	const now = Date.now();
	if (timeline?.closedOn && timeline?.dueOn) {
		if (timeline.closedOn > timeline?.dueOn) return ETimeliness.enum.late;
		return ETimeliness.enum.onTrack;
	}
	if (timeline?.dueOn) {
		if (now > Date.parse(timeline.dueOn)) return ETimeliness.enum.late;
		return ETimeliness.enum.onTrack;
	}
	return ETimeliness.enum.atRisk;
}
