import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CaretRightIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import React from "react";

import { UpdateProcessState } from "@metronome/features/UpdateProcessState";
import { ProcessDetailsMenu } from "@metronome/features/ViewProcessDetailsDialog";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import type {
	ILinkMultiLevel,
	ILinkMultiLevelProcessInstance,
} from "@metronome/types/Link";
import Flag from "./Flag";
import { TimelinessTag } from "./TimelinessTag";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import { useSingleProcessInstance } from "@metronome/api/useProcessInstance";
import { Link } from "@tanstack/react-router";

type DefaultProps = {
	link: ILinkMultiLevel;
};
const DefaultBreadcrumbContent: React.FC<DefaultProps> = ({ link }) => (
	<Link
		className="py-2 flex row items-center gap-2"
		to={link.href}
		params={link.params}
	>
		<span
			className={clsx({
				"whitespace-nowrap truncate max-w-xs": true,
				"text-blue-600 font-semibold": link.active,
			})}
		>
			{link.name}
		</span>
	</Link>
);

type ProcessBreadcrumbContent = {
	link: ILinkMultiLevelProcessInstance;
};

const ProcessInstanceBreadcrumbContent: React.FC<ProcessBreadcrumbContent> = ({
	link,
}) => {
	const { data: processInstance } = useSingleProcessInstance(link.ressourceId);
	const isActive = isProcessActive(processInstance?.state);
	if (processInstance)
		return (
			<div className="flex items-center gap-2 p-1.5 bg-white shadow rounded">
				{!!processInstance && (
					<ProcessDetailsMenu processInstance={processInstance} />
				)}
				<div className="flex-col m-auto gap-2">
					<div className="flex flex-row gap-2 justify-between items-center">
						<Link to={link.href} params={link.params}>
							<span
								className={clsx({
									"truncate sm:max-w-[32] md:max-w-lg	": true,
									"text-blue-600 font-semibold": link.active,
								})}
							>
								{link.name}
							</span>
						</Link>
						<div className="flex flex-row items-center gap-2">
							<Flag
								isFlagged={processInstance.isFlagged ?? false}
								id={processInstance.id}
								context="process-instances"
							/>
							{isActive && processInstance.schedule ? (
								<TimelinessTag timeline={processInstance.schedule} />
							) : undefined}
							<UpdateProcessState processInstance={processInstance} />
						</div>
					</div>
				</div>
			</div>
		);
	return (
		<FontAwesomeIcon
			className="text-blue-600 bg-transparent"
			icon={["fas", "circle-dot"]}
			beatFade
		/>
	);
};

type SwitcherProps = {
	link: ILinkMultiLevel | ILinkMultiLevelProcessInstance;
};
const BreadcrumbContentSwitcher: React.FC<SwitcherProps> = ({ link }) => {
	switch (link.type) {
		case "PROCESS_INSTANCE":
			return <ProcessInstanceBreadcrumbContent link={link} />;

		default:
			return <DefaultBreadcrumbContent link={link} />;
	}
};

const MultiLevelBreadcrumbs: React.FC<{
	links: Array<ILinkMultiLevel | ILinkMultiLevelProcessInstance>;
}> = ({ links }) => {
	if (!links.length) {
		return null;
	}
	const linkElements = links.map((link, index) => (
		<React.Fragment key={`breadcrumb-${link.href}-${link.type}`}>
			<li
				className={`flex row items-center ${
					link.active ? "opacity-100" : "opacity-75"
				} hover:opacity-100 focus-within:opacity-100`}
			>
				<BreadcrumbContentSwitcher link={link} />
			</li>
			{index < links.length - 1 && <CaretRightIcon />}
		</React.Fragment>
	));

	return (
		<div className="mt-2 z-50 relative flex w-fit">
			<ol className="flex items-center list-unstyled flex-row gap-2 text-slate-600 m-0 pt-0 pb-0 ps-0 pe-0 font-normal">
				{linkElements}
			</ol>
		</div>
	);
};

export const BreadcrumbsResponsive: React.FC<{
	links: Array<ILinkMultiLevel | ILinkMultiLevelProcessInstance>;
}> = ({ links }) => {
	const isMobile = useIsMobile();
	if (!links.length) {
		return null;
	}
	if (isMobile) {
		if (links.length === 1) {
			return null;
		}
		return (
			<div className="flex flex-col gap-2 items-center">
				<Link
					className="fw-bold my-2 me-auto"
					to={links[links.length - 2].href}
					params={links[links.length - 2]?.params}
				>
					<FontAwesomeIcon className="me-2" icon={["fas", "angle-left"]} />
					{links[links.length - 2].name}
				</Link>
				<div className="shadow rounded bg-white">
					<BreadcrumbContentSwitcher link={links[links.length - 1]} />
				</div>
			</div>
		);
	}
	return <MultiLevelBreadcrumbs links={links} />;
};
