import { ChevronDownIcon, Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import Select, { components } from "react-select";
import type {
	DropdownIndicatorProps,
	GroupBase,
	MultiValueRemoveProps,
	OptionProps,
	Props,
  SingleValueProps,
} from "react-select";
import Avatar from "./Avatar";
import { getAvatar } from "@metronome/utils/user";

type OptionData = {
  value: string;
  label: string;
  image: string;
};


const DropdownIndicator = <Option,>(props: DropdownIndicatorProps<Option>) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon />
		</components.DropdownIndicator>
	);
};

// biome-ignore lint/suspicious/noExplicitAny: could not get the typing to work with this one...
const ClearIndicator = <Option,>(props: any) => {
	return (
		<components.ClearIndicator {...props}>
			<Cross2Icon />
		</components.ClearIndicator>
	);
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
	return (
		<components.MultiValueRemove {...props}>
			<Cross2Icon />
		</components.MultiValueRemove>
	);
};

const AvatarSingleValue = (props: SingleValueProps<OptionData>) => {
	return (
		<components.SingleValue {...props}>
			<Avatar
				size={20}
				src={getAvatar(props.data.image)}
			/>
			{props.label}
		</components.SingleValue>
	);
};

const AvatarOption = (props: OptionProps<OptionData>) => {
	return (
		<components.Option {...props}>
			<Avatar
				size={28}
				src={getAvatar(props.data.image)}
			/>
      <span className="ms-4">
			  {props.label}
      </span>
		</components.Option>
	);
};

const controlStyles = {
	base: "border rounded-lg bg-white hover:cursor-pointer",
	focus: "border-primary-600 ring-1 ring-primary-500",
	nonFocus: "border-gray-300 hover:border-gray-400",
};
const placeholderStyles = "text-gray-500 pl-1 py-0.5";
const selectInputStyles = "pl-1 py-0.5";
const valueContainerStyles = "p-1 gap-1";
const singleValueStyles = "leading-7 ml-1";
const multiValueStyles =
	"bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6 py-0.5";
const multiValueRemoveStyles =
	"border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
	"text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const indicatorSeparatorStyles = "bg-gray-300";
const dropdownIndicatorStyles =
	"p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
	base: "hover:cursor-pointer px-3 py-2 rounded",
	focus: "bg-gray-100 active:bg-gray-200",
	selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
	"text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";


interface PropsWithAvatar<Option, IsMulti, Group> extends Props<Option> {
  hasIcon: true;
}

export function MultiSelect<
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>(props: PropsWithAvatar<Option, IsMulti, Group>) {
	return (
		<Select
			unstyled
			closeMenuOnSelect={false}
      maxMenuHeight={150}
			hideSelectedOptions={false}
			styles={{
				input: (base) => ({
					...base,
					"input:focus": {
						boxShadow: "none",
					},
				}),
				// On mobile, the label will truncate automatically, so we want to
				// override that behaviour.
				multiValueLabel: (base) => ({
					...base,
					whiteSpace: "normal",
					overflow: "visible",
				}),
				control: (base) => ({
					...base,
					transition: "none",
				}),
			}}
			classNames={{
				control: ({ isFocused }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          controlStyles.base,
        ),
				placeholder: () => placeholderStyles,
				input: () => selectInputStyles,
				valueContainer: () => valueContainerStyles,
				singleValue: () => singleValueStyles,
				multiValue: () => multiValueStyles,
				multiValueLabel: () => multiValueLabelStyles,
				multiValueRemove: () => multiValueRemoveStyles,
				indicatorsContainer: () => indicatorsContainerStyles,
				clearIndicator: () => clearIndicatorStyles,
				indicatorSeparator: () => indicatorSeparatorStyles,
				dropdownIndicator: () => dropdownIndicatorStyles,
				menu: () => menuStyles,
				groupHeading: () => groupHeadingStyles,
				option: ({ isFocused, isSelected }) =>
					clsx(
						isFocused && optionStyles.focus,
						isSelected && optionStyles.selected,
						optionStyles.base,
					),
				noOptionsMessage: () => noOptionsMessageStyles,
			}}
			components={{
				DropdownIndicator,
				ClearIndicator,
				MultiValueRemove,
        ...(props.hasIcon ? {
          Option: AvatarOption,
          SingleValue: AvatarSingleValue,
        } : {})
			}}
			{...props}
		/>
	);
}
