import type { Table } from "@tanstack/react-table";
import { endOfToday } from "date-fns/endOfToday";
import { format } from "date-fns/format";
import { startOfToday } from "date-fns/startOfToday";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@metronome/components/ui/button";
import { EResolution } from "@metronome/types/Resolution";
import { EStepTimeliness } from "@metronome/types/Timeliness";

type FilterToggleProps = {
	onActivate: () => void;
	children: React.ReactNode;
	selected: boolean;
};

function FilterToggle({
	onActivate,
	children,
	selected,
}: FilterToggleProps): JSX.Element {
	return (
		<Button
			onClick={onActivate}
			className={selected ? "outline-2 text-primary-brand bg-blue-200" : ""}
			variant={selected ? "secondary" : "outline"}
		>
			{children}
		</Button>
	);
}

type Props<T> = {
	instance: Table<T>;
};
type CustomFiltersName = "onTrack" | "late" | "atRisk" | "early" | "today" | "";
export function ActivitiesCustomFilters<T>({
	instance,
}: Props<T>): JSX.Element {
	const [lastSelected, setLastSelected] = React.useState<CustomFiltersName>("");

	const resolutionColumn = instance.getColumn("resolution");
	const timelinessColumn = instance.getColumn("timeliness");
	const targetStartColumn = instance.getColumn("schedule.dueDate");

	const filterTimelinessValidation = (
		selected: boolean,
		timeliness: EStepTimeliness,
	): void => {
		// clean previous filter
		targetStartColumn?.setFilterValue(() => undefined);
		if (!selected) {
			setLastSelected(timeliness);
			resolutionColumn?.setFilterValue(() => EResolution.enum.done);
			timelinessColumn?.setFilterValue(() => timeliness);
		} else {
			setLastSelected("");
			resolutionColumn?.setFilterValue(() => undefined);
			timelinessColumn?.setFilterValue(() => undefined);
		}
	};

	const filterToday = (): void => {
		if (!targetStartColumn) return;
		// clean previous filter
		resolutionColumn?.setFilterValue(() => undefined);
		timelinessColumn?.setFilterValue(() => undefined);

		const isSelected = targetStartColumn.getFilterValue() !== undefined;
		if (!isSelected) {
			setLastSelected("today");
			const from = format(startOfToday(), "yyyy-MM-dd");
			const to = format(endOfToday(), "yyyy-MM-dd");
			targetStartColumn.setFilterValue(() => [from, to]);
		} else {
			setLastSelected("");
			targetStartColumn.setFilterValue(() => undefined);
		}
	};

	return (
		<div className="mt-4 flex flex-row items-center gap-2 justify-end">
			<FilterToggle
				selected={lastSelected === "today"}
				onActivate={filterToday}
			>
				<FormattedMessage id="TODAY" />
			</FilterToggle>
			<FilterToggle
				selected={lastSelected === "onTrack"}
				onActivate={() => {
					filterTimelinessValidation(
						lastSelected === "onTrack",
						EStepTimeliness.enum.onTrack,
					);
				}}
			>
				<FormattedMessage id="FILTER.onTimeSteps" />
			</FilterToggle>
			<FilterToggle
				selected={lastSelected === "late"}
				onActivate={() => {
					filterTimelinessValidation(
						lastSelected === "late",
						EStepTimeliness.enum.late,
					);
				}}
			>
				<FormattedMessage id="FILTER.lateSteps" />
			</FilterToggle>
		</div>
	);
}
