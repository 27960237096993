import { z } from "zod";
import { IBusinessDimensionNode, INodeReference } from "./BusinessDimension";
import { IIndicator } from "./Indicator";
import { IModelReference, IRankedModelReference } from "./ModelReference";
import { IParty } from "./Party";
import { IProcessInstance } from "./ProcessInstance";
import { EResolution } from "./Resolution.js";
import { ERoles } from "./Roles";
import { IUserRoleAssignmentsMap } from "./UserRoleAssignmentsMap";

export const IAgenda = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional(),
	optional: z.boolean(),
	duration: z.string(),
	completed: z.boolean(),
	completedOn: z.union([z.date(), z.string()]).optional(),
	completedBy: IParty.optional(),
});
export type IAgenda = z.infer<typeof IAgenda>;

export const EStepInstanceType = z.enum(["event", "task", "hook", "milestone"]);
export type EStepInstanceType = z.infer<typeof EStepInstanceType>;

export const IStepInstanceView = z.object({
	party: IParty,
	viewAt: z.number(),
	raci: ERoles,
});

export type IStepInstanceView = z.infer<typeof IStepInstanceView>;

const ISchedule = z.object({
	targetStart: z.string(),
	deadline: z.string(),
	resolutionDate: z.string().optional(),
	estimatedDuration: z.string(),
	atRiskRange: z.string(),
});
export type ISchedule = z.infer<typeof ISchedule>;

const IProcessInstanceLight = IProcessInstance.pick({
	id: true,
	name: true,
	organizationId: true,
	processStream: true,
	processTemplate: true,
	state: true,
});

type IProcessInstanceLight = z.infer<typeof IProcessInstanceLight>;

export const StepInstanceCompletion = z.object({
	performedBy: IParty,
	performedAt: z.number(),
});
export type StepInstanceCompletion = z.infer<typeof StepInstanceCompletion>;

export const IStepInstance = z.object({
	id: z.string().min(1),
	resolution: EResolution,
	step: IRankedModelReference,
	stage: IRankedModelReference,
	schedule: ISchedule,
	objective: z.string(),
	groundRules: z.string(),
	processInstance: IProcessInstanceLight,
	assignments: IUserRoleAssignmentsMap,
	indicators: z.array(IIndicator),
	agenda: z.array(IAgenda),
	isFlagged: z.boolean(),
	notLaterThanSetByUser: z.boolean(),
	dueDateSetByUser: z.boolean(),
	dueDateModified: z.boolean(),
	notLaterThanModified: z.boolean(),
	businessDimensions: z.array(IBusinessDimensionNode),
	nodeReferences: z.array(INodeReference),
	isBlocked: z.boolean(),
	views: z.array(IStepInstanceView).optional(),
	type: EStepInstanceType,
	completion: StepInstanceCompletion.optional(),
});

export type IStepInstance = z.infer<typeof IStepInstance>;

export const IStepRequirements = z.object({
	name: z.enum(["forms", "attachments", "previousStepInstances"]),
	fulfilled: z.boolean(),
	ref: z.array(
		z.object({
			id: z.string().min(1),
			name: z.string(),
		}),
	),
});
export type IStepRequirements = z.infer<typeof IStepRequirements>;

export const IStepTemplateFilter = z.object({
	name: z.string().min(1),
	ids: z.array(z.string()),
});
export type IStepTemplateFilter = z.infer<typeof IStepTemplateFilter>;

export const IFilters = z.object({
	stepTypes: EStepInstanceType.optional(),
	labels: z.array(IModelReference),
	processStreams: z.array(IModelReference),
	stepTemplates: z.array(IStepTemplateFilter),
	nodeTypes: z.array(IModelReference),
});
export type IFilters = z.infer<typeof IFilters>;

export type StepInstanceContext = "your-work" | "history" | "activities";
