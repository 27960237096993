import { z } from "zod";

export const ITimeline = z.object({
	plannedOn: z.string().optional(),
	dueOn: z.string().optional(),
	startedOn: z.string().optional(),
	closedOn: z.string().optional(),
});

export type ITimeline = z.infer<typeof ITimeline>;
