import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "@metronome/components/ui/button";
import { FormattedMessage, useIntl } from "react-intl";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { Tabs, TabsList, TabsTrigger } from "@metronome/components/ui/tabs";
import { ComboboxPaginated } from "@metronome/components/ComboBoxPaginated";
import { useGetPartiesFiltersInfinite } from "@metronome/api/useFilters";
import { useState } from "react";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@metronome/components/ui/select";
import clsx from "clsx";
import { LightStepInstanceList } from "./LightStepInstanceList";
import { Link } from "@tanstack/react-router";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { format } from "date-fns/format";
import { startOfToday } from "date-fns/startOfToday";
import { endOfToday } from "date-fns/endOfToday";

type OptionType = {
	value: string;
	label: string;
	image?: string;
};

type FormStep = "search" | "results";

export const LogEvent = () => {
	const intl = useIntl();
	const startFrom = format(startOfToday(), "yyyy-MM-dd");
	const workspaceId = useWorkspaceId();
	const startTo = format(endOfToday(), "yyyy-MM-dd");
	const [search, setSearch] = useState("");
	const [value, setValue] = useState<string | undefined>(undefined);
	const [formStep, setFormStep] = useState<FormStep | undefined>("search");
	const {
		data: partyFilters,
		fetchNextPage,
		hasNextPage,
	} = useGetPartiesFiltersInfinite(search);

	const options: OptionType[] =
		partyFilters?.pages
			.flatMap((page) => page?.results)
			.map(
				(modelRef) =>
					({
						value: modelRef?.id,
						label: modelRef?.name,
						image: modelRef?.profilePictureUri,
					}) as OptionType,
			) ?? [];
	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="secondary" className="ms-auto">
					<FormattedMessage id="LOG_EVENT" />
				</Button>
			</DialogTrigger>
			<DialogContent>
				<h2 className="text-lg font-bold">
					<FormattedMessage id="LOG_EVENT" />
				</h2>
				<Tabs>
					<TabsList className="bg-white">
						<TabsTrigger className="font-bold" value="activities">
							<FormattedMessage id="PEOPLE" />
						</TabsTrigger>
						<TabsTrigger className="font-bold" value="resources" disabled>
							<FormattedMessage id="RESOURCES" />
						</TabsTrigger>
						<TabsTrigger className="font-bold" value="delay" disabled>
							<FormattedMessage id="DELAYS" />
						</TabsTrigger>
					</TabsList>
				</Tabs>
				<div
					className={clsx({
						hidden: formStep === "results",
						"flex flex-col gap-2 transition-transform": true,
					})}
				>
					<span>
						<p>
							<FormattedMessage id="LOG_EVENT_DESC" />
						</p>
						<p>
							<FormattedMessage id="LOG_EVENT_DESC_2" />
						</p>
					</span>
					<ComboboxPaginated
						setSearchValue={setSearch}
						value={value}
						setValue={(id) => setValue(id)}
						items={options}
						fetchNextPage={fetchNextPage}
						hasNextPage={hasNextPage}
					/>
					<Select>
						<SelectTrigger>
							<SelectValue placeholder={intl.formatMessage({ id: "REASON" })} />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="out-sick">
								<FormattedMessage id="OUT_SICK" />
							</SelectItem>
							<SelectItem value="TRAININGS">
								<FormattedMessage id="TRAININGS" />
							</SelectItem>
							<SelectItem value="unavailable">
								<FormattedMessage id="UNAVAILABLE" />
							</SelectItem>
						</SelectContent>
					</Select>
					<Button
						className="ms-auto"
						disabled={!value}
						onClick={() => setFormStep("results")}
					>
						<FormattedMessage id="PROCEED" />
						<ChevronRightIcon className="inline ms-2" />
					</Button>
				</div>
				<div
					className={clsx({
						hidden: formStep === "search",
						"flex flex-col gap-2 transition-transform": true,
					})}
				>
					<LightStepInstanceList responsibleId={value}>
						<Button
							variant="secondary"
							className="me-auto"
							onClick={() => setFormStep("search")}
						>
							<ChevronLeftIcon className="inline me-2" />
							<FormattedMessage id="BACK" />
						</Button>
						<Link
							to="/$workspaceId/activities"
							params={{ workspaceId }}
							search={{
								responsibleIds: value ? [value] : undefined,
								startFrom,
								startTo,
							}}
							className="ms-auto text-primary"
						>
							<FormattedMessage id="VIEW_ALL_STEPS" />
						</Link>
					</LightStepInstanceList>
				</div>
			</DialogContent>
		</Dialog>
	);
};
