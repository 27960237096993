import { useOrganizationData } from "@metronome/context/OrganizationData";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import {
	type UseQueryResult,
	useInfiniteQuery,
	useQuery,
	infiniteQueryOptions,
} from "@tanstack/react-query";

import { useRACIData } from "@metronome/context/RACIData";
import { raciRoles } from "@metronome/types/Roles";
import { apiGet } from "./api";

const processStreamKeys = {
	all: (
		workspaceId: string,
		organizationId?: string,
		processCategories?: string[],
		RACI?: string[],
	) =>
		[
			workspaceId,
			"processStreams",
			organizationId,
			processCategories,
			RACI,
		] as const,
	single: (
		workspaceId: string,
		processStreamId: string,
		organizationId?: string,
	) =>
		[
			...processStreamKeys.all(workspaceId, organizationId),
			processStreamId,
		] as const,
};

export function useSingleProcessStreams(
	processStreamId: string,
): UseQueryResult<IProcessStream, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();
	if (!workspaceId) {
		throw new Error("useProcessStreamsInstances: workspaceId is not defined");
	}
	return useQuery({
		queryKey: processStreamKeys.single(
			workspaceId,
			processStreamId,
			activeOrganization,
		),

		queryFn: () =>
			apiGet<IProcessStream>(
				`ws/${workspaceId}/process-streams/${processStreamId}`,
			),
	});
}

type OptionsParams = {
	workspaceId: string;
	organizationId?: string;
	processCategories?: string[];
	RACI?: string[];
	roles: string[];
	pageSize: number;
};

export const infiniteProcessStreamOptions = ({
	workspaceId,
	organizationId,
	processCategories,
	RACI,
	roles,
	pageSize,
}: OptionsParams) =>
	infiniteQueryOptions({
		queryKey: processStreamKeys.all(
			workspaceId,
			organizationId ?? "",
			processCategories,
			RACI,
		),
		queryFn: ({ pageParam }) =>
			apiGet<IPaginatedResults<IProcessStream>>(
				`ws/${workspaceId}/process-streams`,
				{
					params: {
						organizationId,
						racis: roles,
						processCategories,
						page: pageParam,
						pageSize,
					},
				},
			),
		initialPageParam: 1,
		getNextPageParam: (
			lastPage: IPaginatedResults<IProcessStream> | undefined,
		): number | undefined => {
			if (lastPage && lastPage?.page >= lastPage?.totalPages) return undefined;
			return lastPage?.page && lastPage.page + 1;
		},
	});

export function useInfiniteProcessStreams(
	processCategories?: string[],
	pageSize?: number,
) {
	const workspaceId = useWorkspaceId();
	const { activeOrganization: organizationId } = useOrganizationData();
	const { RACI } = useRACIData();
	const roles = RACI.map(
		(letter) => raciRoles[letter as keyof typeof raciRoles],
	);
	if (!workspaceId) {
		throw new Error("useInfiniteProcessStreams: workspaceId is not defined");
	}
	return useInfiniteQuery(
		infiniteProcessStreamOptions({
			workspaceId,
			organizationId,
			processCategories,
			RACI,
			roles,
			pageSize: pageSize ?? 10,
		}),
	);
}
