import type { FC } from "react";
import {
	Select,
	SelectItem,
	SelectItemCheck,
	SelectLabel,
	SelectPopover,
	SelectProvider,
} from "@ariakit/react";
import { CaretSortIcon } from "@radix-ui/react-icons";

type ComboBoxMultiSelectProps = {
	items: Array<{ value: string; label: string }>;
	title?: string;
	value: string[];
	setValue: (value: string[]) => void;
};

function renderValue(
	value: string[],
	items: ComboBoxMultiSelectProps["items"],
) {
	if (value.length === 0) return "No item selected";
	if (value.length === 1) return items.find((i) => i.value === value[0])?.label;
	return `${value.length} items selected`;
}

export const ComboBoxMultiSelect: FC<ComboBoxMultiSelectProps> = ({
	items,
	title,
	value,
	setValue,
}) => {
	return (
		<div className="flex flex-col gap-2">
			<SelectProvider value={value} setValue={setValue}>
				{!!title && <SelectLabel>{title}</SelectLabel>}
				<Select className="h-8 flex select-none items-center justify-between gap-1 px-3 py-2 border shadow-sm rounded-md">
					{renderValue(value, items)}
					<CaretSortIcon className="h-4 w-4 opacity-50" />
				</Select>
				<SelectPopover
					gutter={4}
					sameWidth
					unmountOnHide
					className="z-50 flex flex-col border p-1 shadow-sm overflow-auto overscroll-contain max-h-[300px] bg-white rounded-md"
				>
					{items.map(({ value, label }) => (
						<SelectItem
							key={value}
							value={value}
							className="flex items-center gap-1 border-r p-1 cursor-default hover:bg-accent hover:text-accent-foreground"
						>
							<SelectItemCheck />
							{label}
						</SelectItem>
					))}
				</SelectPopover>
			</SelectProvider>
		</div>
	);
};
