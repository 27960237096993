import { useMetricsCount } from "@metronome/api/useMetrics";
import useSelf from "@metronome/api/useSelf";
import { ClockIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Badge } from "./ui/badge";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "./ui/tooltip";

type BadgeKPIProps = {
	value: number;
	color: string;
	tooltipMessage: string;
	children: React.ReactNode;
};

const BadgeKPI: React.FC<BadgeKPIProps> = ({
	value,
	children,
	color,
	tooltipMessage,
}) => {
	return (
		<TooltipProvider delayDuration={0}>
			<Tooltip>
				<TooltipTrigger>
					<Badge className={color} variant="secondary">
						<FormattedNumber value={value} />
						{children}
					</Badge>
				</TooltipTrigger>
				<TooltipContent>
					<p className="font-bold">
						<FormattedMessage id={tooltipMessage} />
					</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

type KPIYourWorkProps = {
	activeTab: "steps" | "processes";
};
export const KPIYourWork: React.FC<KPIYourWorkProps> = ({ activeTab }) => {
	const { data: self } = useSelf();
	const { data: metrics } = useMetricsCount({
		responsibleId: self?.id ?? "",
		method: "count",
		metrics: [
			{
				name: "countOfLateStepInstances",
			} as const,
			{
				name: "countOfAtRiskStepInstances",
			} as const,
			{
				name: "countOfAtRiskProcessInstances",
			} as const,
			{
				name: "countOfLateProcessInstances",
			} as const,
		],
	});

	const lateStepInstance = metrics?.find(
		(m) => m.name === "countOfLateStepInstances",
	)?.result as number;

	const atRiskStepInstance = metrics?.find(
		(m) => m.name === "countOfAtRiskStepInstances",
	)?.result as number;

	const atRiskProcessInstance = metrics?.find(
		(m) => m.name === "countOfAtRiskProcessInstances",
	)?.result as number;

	const lateProcessInstance = metrics?.find(
		(m) => m.name === "countOfLateProcessInstances",
	)?.result as number;

	return (
		<div className="flex flex-col text-sm ms-auto gap-1">
			{!!lateStepInstance || !!atRiskStepInstance ? (
				<div
					className={clsx({
						"flex gap-2 items-center justify-start": true,
						"text-slate-500": activeTab !== "steps",
					})}
				>
					<span className="font-semibold">
						<FormattedMessage id="STEPS" />:
					</span>

					{!!lateStepInstance && (
						<BadgeKPI
							color="text-red-600"
							value={lateStepInstance}
							tooltipMessage="KPI.LATE.STEP"
						>
							<ClockIcon className="inline text-red-600 ms-1" />
						</BadgeKPI>
					)}
					{!!atRiskStepInstance && (
						<BadgeKPI
							color="text-orange-600"
							value={atRiskStepInstance}
							tooltipMessage="KPI.AT_RISK.STEP"
						>
							<ExclamationTriangleIcon className="inline text-orange-600 ms-1" />
						</BadgeKPI>
					)}
				</div>
			) : null}
			{!!lateProcessInstance || !!atRiskProcessInstance ? (
				<div
					className={clsx({
						"flex gap-2 items-center justify-start": true,
						"text-slate-500": activeTab !== "processes",
					})}
				>
					<span className="font-semibold">
						<FormattedMessage id="PROCESSES" />:
					</span>

					{!!lateProcessInstance && (
						<BadgeKPI
							color="text-red-600"
							value={lateProcessInstance}
							tooltipMessage="KPI.LATE.PROCESS"
						>
							<ClockIcon className="inline text-red-600 ms-1" />
						</BadgeKPI>
					)}
					{!!atRiskProcessInstance && (
						<BadgeKPI
							color="text-orange-600"
							value={atRiskProcessInstance}
							tooltipMessage="KPI.AT_RISK.PROCESS"
						>
							<ExclamationTriangleIcon className="inline text-orange-600 ms-1" />
						</BadgeKPI>
					)}
				</div>
			) : null}
		</div>
	);
};
