import { useStepInstancesForLogEvents } from "@metronome/api/useStepInstance";
import AvatarsList from "@metronome/components/AvatarsList";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { Badge } from "@metronome/components/ui/badge";
import { Button } from "@metronome/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { atEndOfDay, atMidnight } from "@metronome/utils/dateHelpers";
import { format } from "date-fns/format";
import type React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "@tanstack/react-router";

type LightStepInstanceListProps = {
	responsibleId?: string;
	children?: React.ReactNode;
};

export const LightStepInstanceList: React.FC<LightStepInstanceListProps> = ({
	responsibleId,
	children,
}) => {
	const workspaceId = useWorkspaceId();
	const startFrom = atMidnight(format(new Date(), "yyyy-MM-dd"))?.toJSON();
	const startTo = atEndOfDay(format(new Date(), "yyyy-MM-dd"))?.toJSON();
	const { data: stepInstances, isLoading } = useStepInstancesForLogEvents(
		{
			page: 1,
			pageSize: 8,
			processStates: ["active"],
			responsibleIds: responsibleId,
			startFrom,
			startTo,
		},
		!!responsibleId,
	);

	if (isLoading) {
		return <LoadingMetronome />;
	}

	if (!stepInstances) {
		return null;
	}

	return (
		<div>
			<div className="flex items-center justify-between py-2">
				<strong>
					{stepInstances.totalItems} <FormattedMessage id="STEPS" /> :
				</strong>
				<Badge variant="secondary">
					<FormattedDate value={new Date()} />
				</Badge>
			</div>
			<div className="flex flex-col gap-1 py-2">
				{stepInstances.results.map((stepInstance) => (
					<div
						className="flex justify-between items-center gap-4 p-1 rounded-sm shadow-sm bg-white"
						key={stepInstance.id}
					>
						<Link
							to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
							params={{
								workspaceId,
								processId: stepInstance.processInstance.id,
								stepId: stepInstance.id,
							}}
							className="underline"
						>
							<h3>{stepInstance.step.name}</h3>
						</Link>
						<div className="overflow-auto rounded-r ms-auto">
							<AvatarsList
								numberToDisplay={3}
								wideSpacing
								users={stepInstance.assignments.responsibleAssignees}
							/>
						</div>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="secondary">
									<FormattedMessage id="ACTIONS" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								<DropdownMenuItem>
									<Link
										to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
										params={{
											workspaceId,
											processId: stepInstance.processInstance.id,
											stepId: stepInstance.id,
										}}
									>
										<FormattedMessage id="VIEW" />
									</Link>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				))}
			</div>
			<div className="flex items-center justify-between">{children}</div>
		</div>
	);
};
