import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useForms from "@metronome/api/useForms";
import useStepInstance from "@metronome/api/useStepInstance";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
	accordionStyles,
} from "@metronome/components/Accordion";
import type React from "react";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useAttachments from "@metronome/api/useAttachments";
import useEntityHistories from "@metronome/api/useHistory";
import { PaginatedList } from "@metronome/components/PaginatedList";
import { defaultPagination } from "@metronome/constants/pagination";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import type { IPagination } from "@metronome/types/PaginatedResponse";

import { Attachment } from "@metronome/components/Attachment";
import FallbackView from "@metronome/components/FallbackView";
import { IndeterminateProgressBar } from "@metronome/components/IndeterminateProgressBar";
import { BreadcrumbsResponsive } from "@metronome/components/MultiLevelBreadcrumbs";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type {
	ILinkMultiLevel,
	ILinkMultiLevelProcessInstance,
} from "@metronome/types/Link";
import { Markup } from "interweave";
import { AttachmentsTab } from "../AttachmentsTab";
import { AgendaActivitiesTemplate } from "./AgendaActivitiesTemplate";
import { StepInstanceTemplate } from "./StepInstanceTemplate";
import styles from "./stepInstancePage.module.scss";
import FormsTab from "./tabs/FormsTab";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { useNodeReferenceSpecs } from "@metronome/api/useMetadataDefinitions";
import { IconNodeCategory } from "@metronome/components/IconNodeCategory";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@metronome/components/ui/tooltip";
import { MetadataList } from "@metronome/features/MetadataList";
import { ConnectedNodes } from "@metronome/features/ConnectedNodes";
import { HistoryTemplate } from "@metronome/features/History/HistoryTemplate";
import { NotesTab } from "@metronome/features/Notes";

type Context = "step-instances";

const StepInstancePage: React.FC<{
	stepId: string;
	isSideOpen?: boolean;
	isModal?: boolean;
}> = ({ isSideOpen = false, isModal = false, stepId }) => {
	const intl = useIntl();
	const workspaceId = useWorkspaceId();
	const [pagination, setPagination] = useState<IPagination>({
		...defaultPagination,
		page: Number.parseInt(location.hash.substring(1), 10) || 1,
	});

	const { data: stepInstance, isLoading } = useStepInstance(stepId ?? "");
	const { data: forms } = useForms(stepInstance?.id);
	const { data: histories } = useEntityHistories(
		"step-instances",
		{
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
		stepInstance?.id,
	);

	useSetCurrentOrganization(
		isModal ? undefined : stepInstance?.processInstance.organizationId,
	);

	const { data: attachments } = useAttachments<Context>(
		"step-instances",
		stepId,
	);

	const referencedNodes = useMemo(() => {
		if (!stepInstance) return [];
		return stepInstance.nodeReferences
			?.map((nr) => nr.referencedNode)
			.sort((a, b) => a.tree.name.localeCompare(b.tree.name));
	}, [stepInstance]);

	const nodeIds = useMemo(
		() => stepInstance?.businessDimensions?.map((nr) => nr.id),
		[stepInstance],
	);
	const { data: referenceSpecs } = useNodeReferenceSpecs(nodeIds);

	if (!stepInstance) {
		return <FallbackView />;
	}

	const defaultOpenedAccordion = [
		"objective",
		"groundRules",
		"businessDimensions",
		"references",
		"nodeReferences",
		"attachments",
		"notes",
		"activities",
		"forms",
	];

	const breadcrumbsLinks: Array<
		ILinkMultiLevel | ILinkMultiLevelProcessInstance
	> = [
		{
			active: false,
			type: "PROCESS_STREAM",
			name: stepInstance.processInstance.processStream.name,
			href: "/$workspaceId/stream/$streamId",
			params: {
				workspaceId,
				streamId: stepInstance.processInstance.processStream.id,
			},
		},
		{
			active: false,
			ressourceId: stepInstance.processInstance.id,
			type: "PROCESS_INSTANCE",
			name: stepInstance.processInstance.name,
			href: "/$workspaceId/process/$processId",
			params: {
				workspaceId,
				processId: stepInstance.processInstance.id,
			},
		},
		{
			active: true,
			type: "STEP_INSTANCE",
			name: `${stepInstance.step.name} : ${stepInstance.businessDimensions
				.map((bd) => bd.name)
				.join(", ")}`,
			href: "#",
			params: {},
		},
	];

	return (
		<div className="flex flex-col gap-4 grow relative">
			{!!(!isSideOpen && !isModal) && (
				<BreadcrumbsResponsive links={breadcrumbsLinks} />
			)}
			{isLoading && (
				<div className={styles.overlayLoader}>
					<IndeterminateProgressBar />
				</div>
			)}
			<StepInstanceTemplate
				stepInstance={stepInstance}
				isModal={isModal}
				isSideOpen={isSideOpen}
			>
				<div>
					<span className="uppercase text-xs font-semibold pb-2">
						<FormattedMessage id="RESOURCES" />
					</span>
					<div className="flex items-center gap-2">
						{stepInstance.nodeReferences?.map((nodeReference) => (
							<TooltipProvider key={nodeReference.id}>
								<Tooltip>
									<TooltipTrigger>
										<Dialog>
											<DialogTrigger asChild>
												<div
													key={nodeReference.id}
													className="w-8 h-8 bg-[wheat] rounded-full text-center grid place-items-center"
												>
													<IconNodeCategory
														category={nodeReference.referencedNode.tree.type}
													/>
												</div>
											</DialogTrigger>
											<DialogContent className="max-h-[calc(100vh-100px)] overflow-y-auto">
												<MetadataList
													context="step-instances"
													contextId={stepInstance.id}
													businessDimensionNodes={referencedNodes.filter(
														(node) =>
															node.id === nodeReference.referencedNode.id,
													)}
												/>
											</DialogContent>
										</Dialog>
									</TooltipTrigger>
									<TooltipContent>
										{nodeReference.referencedNode.name}
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						))}
						{referenceSpecs && (
							<ConnectedNodes
								stepInstance={stepInstance}
								referenceSpecs={referenceSpecs}
							/>
						)}
					</div>
				</div>
				<Accordion
					defaultValue={defaultOpenedAccordion}
					className="flex flex-col gap-4 pb-12 mt-4"
					type="multiple"
				>
					{!!stepInstance.objective && (
						<AccordionItem value="objective">
							<AccordionTrigger asChild>
								<div className={accordionStyles.default.trigger}>
									<FontAwesomeIcon
										className={accordionStyles.default.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.OBJECTIVE",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<Markup
										className={styles.breakSpaces}
										content={stepInstance.objective}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{!!stepInstance.groundRules && (
						<AccordionItem value="groundRules">
							<AccordionTrigger asChild>
								<div className={accordionStyles.default.trigger}>
									<FontAwesomeIcon
										className={accordionStyles.default.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.GROUND_RULES",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<Markup
										className={styles.breakSpaces}
										content={stepInstance.groundRules}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}

					{!!attachments?.preloaded.length && (
						<AccordionItem value="references">
							<AccordionTrigger asChild>
								<div className={accordionStyles.default.trigger}>
									<FontAwesomeIcon
										className={accordionStyles.default.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.REFERENCES",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div className="m-2">
									{attachments?.preloaded?.map((attachment) => (
										<Attachment
											key={attachment.id}
											context="step-instances"
											contextId={stepId}
											attachment={attachment}
											preloaded
										/>
									))}
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{!!(
						attachments?.additional?.length || attachments?.required?.length
					) && (
						<AccordionItem value="attachments">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<FontAwesomeIcon
										className={styles.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.TABS.ATTACHMENTS",
										})}
										{` (${
											attachments?.additional?.length +
											attachments?.required?.length
										})`}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div className={styles.content}>
									<AttachmentsTab
										context="step-instances"
										contextId={stepInstance.id}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{/* activities */}
					{!!stepInstance.agenda?.length && (
						<AccordionItem value="activities">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<FontAwesomeIcon
										className={styles.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "STEP_INSTANCE.TABS.AGENDA_ACTIVITIES",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<AgendaActivitiesTemplate
										agenda={stepInstance.agenda}
										stepInstanceId={stepId}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{/* forms */}
					{!!forms?.length && (
						<AccordionItem value="forms">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<FontAwesomeIcon
										className={styles.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({ id: "STEP_INSTANCE.TABS.FORMS" })}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent asChild>
								<div>
									<FormsTab
										stepInstanceId={stepInstance.id}
										resolution={stepInstance.resolution}
									/>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
					{/* notes */}
					<AccordionItem value="notes">
						<AccordionTrigger asChild>
							<div className={styles.trigger}>
								<FontAwesomeIcon
									className={styles.chevron}
									icon={["fas", "angle-down"]}
								/>
								<span className="font-bold text-base">
									{intl.formatMessage({ id: "STEP_INSTANCE.TABS.NOTES" })}
								</span>
							</div>
						</AccordionTrigger>
						<AccordionContent asChild>
							<div>
								<NotesTab context={"step-instances"} contextId={stepId} />
							</div>
						</AccordionContent>
					</AccordionItem>
					{/* histories */}
					{!!histories?.results?.length && (
						<AccordionItem value="histories">
							<AccordionTrigger asChild>
								<div className={styles.trigger}>
									<FontAwesomeIcon
										className={styles.chevron}
										icon={["fas", "angle-down"]}
									/>
									<span className="font-bold text-base">
										{intl.formatMessage({
											id: "TABS.HISTORY",
										})}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent>
								<div>
									<PaginatedList
										pagination={pagination}
										totalPages={histories?.totalPages}
										onPageChange={(newPagination: IPagination) =>
											setPagination(newPagination)
										}
									>
										<HistoryTemplate
											stepInstanceType={stepInstance.type}
											histories={histories.results}
										/>
									</PaginatedList>
								</div>
							</AccordionContent>
						</AccordionItem>
					)}
				</Accordion>
			</StepInstanceTemplate>
		</div>
	);
};
export default StepInstancePage;
