import type { IGate } from "@metronome/types/Gate";
import type { FC } from "react";
import { useParams } from "@tanstack/react-router";
import { StageList } from "./StageList";

type StageListSwitchProps = {
	gates: IGate[];
};

export const StageListSwitch: FC<StageListSwitchProps> = ({ gates }) => {
	const { processId, stepId: stepInstanceId } = useParams({
		strict: false,
		experimental_returnIntersection: true,
	});

	const currentGate = gates.find((gate) =>
		gate.steps.find((step) =>
			step.stepInstances.find((instance) => instance.id === stepInstanceId),
		),
	);

	const currentStepGroup = currentGate?.steps.find((step) =>
		step.stepInstances.find((instance) => instance.id === stepInstanceId),
	);

	return (
		<div className="max-h-[70vh] overflow-y-auto">
			{gates.map((stage, index) => (
				<StageList
					index={index + 1}
					stage={stage}
					key={`newgate-${stage.id}`}
					firstOpenStageId={currentGate?.id}
					firstOpenStepGroup={currentStepGroup?.id}
					processInstanceId={processId}
				/>
			))}
		</div>
	);
};
