import { createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

const ActivitiesSearchParams = v.object({
	search: v.optional(v.string()),
	stepId: v.optional(v.string()), // this optional step id is used for modal view
	page: v.optional(v.number()),
	pageSize: v.optional(v.number()),
	sortOrder: v.optional(v.union([v.literal("asc"), v.literal("desc")])),
	sortBy: v.optional(v.string()),
	startFrom: v.optional(v.string()),
	startTo: v.optional(v.string()),
	resolution: v.optional(v.array(v.string())),
	timeliness: v.optional(v.string()),
	responsibleIds: v.optional(v.array(v.string())),
});

export const Route = createFileRoute("/$workspaceId/activities")({
	validateSearch: (searchParams) =>
		v.parse(ActivitiesSearchParams, searchParams),
});
