import { useUpdateNodeName } from "@metronome/api/useProcessInstance";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import DisplayDoerType from "@metronome/components/DisplayDoerType";
import EditableField from "@metronome/components/EditableField";
import { Lozenge } from "@metronome/components/Lozenge";
import { DropdownMenuItem } from "@metronome/components/ui/dropdown-menu";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type ProcessDetailsDialogProps = {
	processInstance: IProcessInstance;
};

export const ProcessDetailsDialog: React.FC<ProcessDetailsDialogProps> = ({
	processInstance,
}) => {
	const [open, setOpen] = useState(false);
	const intl = useIntl();

	const { mutate: mutateNodeName } = useUpdateNodeName(processInstance.id);

	const handleNodeNameChange = useCallback(
		(nodeId: string, value: string): void => {
			mutateNodeName({ nodeId, name: value });
		},
		[mutateNodeName],
	);

	const processDetails = [
		{
			title: intl.formatMessage({ id: "PROCESS.NAME" }),
			value: processInstance.name,
			id: "name",
		},
		{
			title: intl.formatMessage({ id: "PROCESS_TEMPLATE.DESCRIPTION" }),
			value: processInstance.processTemplate.description ? (
				<p className="line-clamp line-clamp-3">
					{processInstance.processTemplate.description}
				</p>
			) : undefined,
			id: "description",
		},
		{
			title: intl.formatMessage({ id: "PROCESS_TEMPLATE.VERSION" }),
			value: processInstance.processTemplate.versionName,
			id: "versionName",
		},
		{
			title: intl.formatMessage({ id: "PROCESS_TEMPLATE.CATEGORY" }),
			value: processInstance.processTemplate.category,
			id: "category",
		},
	];

	const scopeNodes = useMemo(
		() =>
			processInstance.businessDimensions?.map((bd) => {
				if (bd?.description)
					return (
						<div className="flex flex-row gap-2 items-center">
							<EditableField
								value={bd.name}
								type="text"
								onValidate={(v) => handleNodeNameChange(bd.id, v)}
							/>
						</div>
					);
				return (
					<div key={bd.id} className="flex flex-row gap-2 items-center">
						<EditableField
							value={bd.name}
							type="text"
							onValidate={(v) => handleNodeNameChange(bd.id, v)}
						/>
					</div>
				);
			}),
		[handleNodeNameChange, processInstance.businessDimensions],
	);
	return (
		<Dialog modal open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<DropdownMenuItem
					onSelect={(e) => {
						e.preventDefault();
						setOpen(true);
					}}
				>
					<InfoCircledIcon className="me-2" />
					<FormattedMessage id="DETAILS" />
				</DropdownMenuItem>
			</DialogTrigger>
			<DialogContent size="small">
				<div>
					<div className="flex flex-col items-start gap-2">
						<span className="font-semibold text-slate-900">
							<FormattedMessage id="DETAILS" />
						</span>
						<div className="flex flex-col">
							<span className="uppercase text-xs font-semibold text-slate-500">
								<FormattedMessage id="CREATED_BY" />
							</span>
							{processInstance.businessDimensions?.map((n) => {
								if (n?.createdBy?.type)
									return (
										<>
											<DisplayDoerType
												key={n.createdBy.type}
												doer={n.createdBy}
											/>
										</>
									);
							})}
						</div>
						<div>
							<span className="uppercase text-xs font-semibold text-slate-500">
								<FormattedMessage id="SCOPE" />
							</span>
							{scopeNodes}
						</div>
						{processDetails
							.filter((val) => val.value)
							.map((detail) => (
								<div key={detail.id} className="flex flex-col relative">
									<span className="uppercase text-xs font-semibold text-slate-500">
										{detail.title}
									</span>
									<span>{detail.value}</span>
								</div>
							))}
						{!!processInstance?.labels?.length && (
							<div className="flex flex-col">
								<span className="uppercase text-xs font-semibold text-slate-500">
									<FormattedMessage id="LABELS" /> :
								</span>
								<div className="flex flex-row flex-wrap gap-2">
									{processInstance.labels.map((label) => (
										<Lozenge appearance="default" key={label}>
											{label}
										</Lozenge>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
