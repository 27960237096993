import { useEffect, useMemo, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import Resizer from "../Resize";
import LoadingMetronome from "./LoadingMetronome.jsx";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.js",
	import.meta.url,
).toString();

type PdfConverterProps = {
	pdfUrl: string;
	fileName: string;
	onChange: (canvas: HTMLCanvasElement) => void;
};

type ImgConverterProps = {
	file: File;
	onChange: (previewUri: string) => void;
};

export const PdfThumbnail: React.FC<PdfConverterProps> = ({
	pdfUrl,
	fileName,
	onChange,
}) => {
	const [imgUrl, setImgUrl] = useState("");

	const UrlUploader = (url: string) => {
		fetch(url).then((response) => {
			response.blob().then((blob) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					if (e.target?.result) {
						const data = atob(
							e.target.result.toString().replace(/.*base64,/, ""),
						);
						convertPdfToImg(data);
					}
				};
				reader.readAsDataURL(blob);
			});
		});
	};

	useMemo(() => {
		UrlUploader(pdfUrl);
	}, [pdfUrl, UrlUploader]);

	const convertPdfToImg = async (data: string) => {
		const canvas = document.createElement("canvas");
		canvas.setAttribute("className", "canv");
		const pdf = await pdfjs.getDocument({ data }).promise;

		const page1 = await pdf.getPage(1);
		const viewport = page1.getViewport({ scale: 1 });

		canvas.height = 400; 
		canvas.width = (viewport.width * 400) / viewport.height;

		const render_context = {
			canvasContext: canvas.getContext("2d") ?? new CanvasRenderingContext2D(),
			viewport: viewport,
		};

		await page1.render(render_context).promise;

		const img = canvas.toDataURL("image/png");
		onChange(canvas);
		setImgUrl(img);
	};

	return (
		<>
			{imgUrl ? (
				<img className="h-52 object-contain" src={imgUrl} alt={fileName} />
			) : (
				<LoadingMetronome />
			)}
		</>
	);
};

export const ImgThumbnail: React.FC<ImgConverterProps> = ({
	file,
	onChange,
}) => {
	const [imgUri, setImgUri] = useState("");

	const fileChangedHandler = (file: File) => {
		try {
			Resizer.imageFileResizer(
				file,
				400,
				400,
				"JPEG",
				100,
				0,
				(uri: string) => {
					onChange(uri);
					setImgUri(uri);
				},
				"base64",
				150,
				150,
			);
		} catch (err) {
			console.log(err);
		}
	};

	useMemo(() => {
		fileChangedHandler(file);
	}, [file, fileChangedHandler]);

	return (
		<>
			{imgUri && (
				<img className="object-contain" src={imgUri} alt={file.name} />
			)}
		</>
	);
};

//Prevent memory leaks
export function useObjectUrls() {
	const mapRef = useRef<Map<File, string> | null>(null);

	useEffect(() => {
		const map = new Map();
		mapRef.current = map;
		return () => {
			for (const [file, url] of map) {
				URL.revokeObjectURL(url);
			}
			mapRef.current = null;
		};
	}, []);

	return function getObjectUrl(file: File) {
		const map = mapRef.current;
		if (!map) {
			throw Error("Cannot getObjectUrl while unmounted");
		}
		if (!map.has(file)) {
			const url = URL.createObjectURL(file);
			map.set(file, url);
		}
		const url = map.get(file);
		if (!url) {
			throw Error("Object url not found");
		}
		return url;
	};
}
