import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import { DropdownMenuItem } from "@metronome/components/ui/dropdown-menu";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnList } from "@metronome/components/ColumnList";
import AvatarsList from "@metronome/components/AvatarsList";
import { ERoles } from "@metronome/types/Roles";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { PersonIcon } from "@radix-ui/react-icons";
import { UpdateAssignments } from "../UpdateAssignments";
import { CreateAssignment } from "../CreateAssignment";

type ProcessRaciDialogProps = {
	processInstance: IProcessInstance;
};

export const ProcessRaciDialog: React.FC<ProcessRaciDialogProps> = ({
	processInstance,
}) => {
	const intl = useIntl();
	const [open, setOpen] = useState(false);

	const {
		responsibleAssignees,
		accountableAssignees,
		consultedAssignees,
		informedAssignees,
	} = processInstance.userRoleAssignmentsMap;

	const { responsibleRoles, accountableRoles } =
		processInstance.roleRequirements;

	const watchers = [
		...accountableAssignees,
		...consultedAssignees,
		...informedAssignees,
	];

	const rolesAndResponsibilites = [
		{
			title: intl.formatMessage({
				id: "ASSIGNEE",
			}),
			value: responsibleRoles.length ? (
				responsibleAssignees.length ? (
					<AvatarsList users={responsibleAssignees} wideSpacing>
						<UpdateAssignments
							parties={responsibleAssignees}
							context="process-instances"
							contextId={processInstance.id}
							role={ERoles.enum.responsible}
							assignments={processInstance.userRoleAssignmentsMap}
							isDisabled={processInstance.state === "closed"}
						/>
					</AvatarsList>
				) : (
					<CreateAssignment
						context="process-instances"
						contextId={processInstance.id}
						role={ERoles.enum.responsible}
					/>
				)
			) : (
				<span className="text-xs">
					<FormattedMessage id="NO_ROLES" />
				</span>
			),
			id: "responsibleAssignees",
		},
		{
			title: intl.formatMessage({
				id: "WATCHERS",
			}),
			value: accountableRoles.length ? (
				accountableAssignees?.length ? (
					<AvatarsList users={watchers}>
						<UpdateAssignments
							parties={watchers}
							context="process-instances"
							contextId={processInstance.id}
							role={ERoles.enum.accountable}
							assignments={processInstance.userRoleAssignmentsMap}
							isDisabled={processInstance.state === "closed"}
							label="WATCHERS"
						/>
					</AvatarsList>
				) : (
					<CreateAssignment
						context="process-instances"
						contextId={processInstance.id}
						role={ERoles.enum.accountable}
					/>
				)
			) : (
				<span className="text-xs">
					<FormattedMessage id="NO_ROLES" />
				</span>
			),
			id: "accountableAssignees",
		},
	];

	return (
		<Dialog modal open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<DropdownMenuItem
					onSelect={(e) => {
						e.preventDefault();
						setOpen(true);
					}}
				>
					<PersonIcon className="me-2" />
					<FormattedMessage id="ASSIGNEE-WATCHER" />
				</DropdownMenuItem>
			</DialogTrigger>
			<DialogContent size="small">
				<div>
					<ColumnList list={rolesAndResponsibilites} />
				</div>
			</DialogContent>
		</Dialog>
	);
};
