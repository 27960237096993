import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import { MetadataList } from "../MetadataList";
import { DropdownMenuItem } from "@metronome/components/ui/dropdown-menu";
import { FormattedMessage } from "react-intl";
import { IProcessInstance } from "@metronome/types/ProcessInstance";
import { useState } from "react";
import { FileTextIcon } from "@radix-ui/react-icons";

type ProcessMetadataDialogProps = {
	processInstance: IProcessInstance;
};
export const ProcessMetadataDialog: React.FC<ProcessMetadataDialogProps> = ({
	processInstance,
}) => {
	const [open, setOpen] = useState(false);
	if (
		Array.isArray(processInstance.businessDimensions) &&
		processInstance.businessDimensions?.length
	)
		return (
			<Dialog modal open={open} onOpenChange={setOpen}>
				<DialogTrigger asChild>
					<DropdownMenuItem
						onSelect={(e) => {
							e.preventDefault();
							setOpen(true);
						}}
					>
						<FileTextIcon className="me-2" />
						<FormattedMessage id="METADATA" />
					</DropdownMenuItem>
				</DialogTrigger>
				<DialogContent size="small">
					<div className="flex flex-col gap-2 items-start">
						<span className="font-semibold text-slate-900">
							<FormattedMessage id="METADATA" />
						</span>
						<MetadataList
							context="process-instances"
							contextId={processInstance.id}
							businessDimensionNodes={processInstance.businessDimensions}
						/>
					</div>
				</DialogContent>
			</Dialog>
		);

	return null;
};
