import { useUpdateAssignments } from "@metronome/api/useAssignments";
import { Avatar } from "@metronome/components/Avatar";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@metronome/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import type { IParty } from "@metronome/types/Party";
import { type ERoles, mapRolesAssignments } from "@metronome/types/Roles";
import type { IUserRoleAssignmentsMap } from "@metronome/types/UserRoleAssignmentsMap";
import { getInitials } from "@metronome/utils/formatText";
import { getUserUri } from "@metronome/utils/user";
import { Cross2Icon, Pencil1Icon } from "@radix-ui/react-icons";
import { type FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AssignmentModal } from "./Modals/AssignmentModal";

type UpdateAssignmentsProps = {
	parties: IParty[];
	contextId: string;
	context: "step-instances" | "process-instances";
	assignments: IUserRoleAssignmentsMap;
	role: ERoles;
	isDisabled?: boolean;
	label?: "ASSIGNEES" | "WATCHERS";
};

export const UpdateAssignments: FC<UpdateAssignmentsProps> = ({
	parties,
	context,
	contextId,
	role,
	assignments,
	isDisabled = false,
	label = "ASSIGNEES",
}) => {
	const [open, setOpen] = useState(false);

	const selectedRole = mapRolesAssignments[role];
	const defaultAssignments =
		assignments[selectedRole].map((da) => ({
			partyId: da.id,
			partyName: `${da.lastName.toLocaleUpperCase()} ${da.firstName}`,
			roleId: da.roles[0].id,
			roleName: da.roles[0].name,
		})) ?? [];

	const { mutate: updateAssignment } = useUpdateAssignments(context, contextId);

	/*
	 * below is sub optimal, but this hopefully will be refactored in the future
	 * with new API endpoints
	 */
	const onRemoveUser = useCallback(
		(id: string) => {
			updateAssignment({
				raci: role,
				assignments: defaultAssignments
					.filter((a) => a.partyId !== id)
					.filter((a) => a.roleId?.length && a.partyId?.length)
					.map((a) => ({ partyId: a.partyId, roleId: a.roleId })),
			});
		},
		[defaultAssignments, role, updateAssignment],
	);

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger disabled={isDisabled} className="p-1">
					<Pencil1Icon />
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem onSelect={() => setOpen(true)}>
						<FormattedMessage id="ADD_ASSIGNEE" />
					</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuLabel>
						<FormattedMessage id={label} />
					</DropdownMenuLabel>
					{parties.map((party) => (
						<DropdownMenuItem
							className="flex gap-2 items-center group"
							key={party.id}
							onSelect={() => onRemoveUser(party.id)}
						>
							<Avatar
								src={getUserUri(party)}
								alt={getInitials(party?.firstName, party?.lastName)}
							/>
							<div className="flex flex-col px-2">
								<span className="font-semibold">{party.name}</span>
								<span className="text-xs">
									{party.roles.map((role) => role?.name)?.join(", ")}
								</span>
							</div>
							<Cross2Icon className="ml-auto opacity-0 group-hover:opacity-100 text-red-500" />
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
			<Dialog modal={true} open={open} onOpenChange={setOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							<FormattedMessage id={`ADD_${role.toUpperCase()}_ASSIGNEE`} />
						</DialogTitle>
					</DialogHeader>
					<AssignmentModal
						contextId={contextId}
						context={context}
						assignments={defaultAssignments}
						role={role}
						onSuccess={() => setOpen(false)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
