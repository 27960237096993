import type React from "react";
import { Avatar } from "@metronome/components/Avatar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { getInitials } from "@metronome/utils/formatText";
import { displayRoles, useName, getUserInfoUri } from "@metronome/utils/user";
import type { IParty } from "@metronome/types/Party";

const UserPopoverComplete: React.FC<{ user: IParty }> = ({ user }) => {
	const initials = getInitials(user?.firstName, user?.lastName);
	return (
		<Popover>
			<PopoverTrigger className="p-0 m-0 bg-transparent">
				<Avatar src={getUserInfoUri(user)} alt={initials} />
			</PopoverTrigger>
			<PopoverContent>
				<div className="text-base text-center">
					<div className="mb-2">
						<Avatar
							size={48}
							src={getUserInfoUri(user)}
							alt={getInitials(user?.firstName, user?.lastName)}
						/>
					</div>
					<div>
						<p className="font-bold">{useName(user)}</p>
						<p className="">{displayRoles(user)}</p>
						<a className="" href={`mailto:${user.email}`}>
							{user.email}
						</a>
					</div>
				</div>
			</PopoverContent>
		</Popover>
	);
};

export const UserPopover: React.FC<{ user: IParty }> = ({ user }) => {
	if (user) return <UserPopoverComplete user={user} />;
	return <span />;
};
