import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IModelReference } from "@metronome/types/ModelReference";
import type { StepInstanceAssignment } from "@metronome/types/PartyAssignment";
import type { ERoles } from "@metronome/types/Roles";
import type { AxiosResponse } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import { apiGet, apiPut } from "./api";

const AssignmentBaseParams = z.object({
	context: z.enum(["step-instances", "process-instances"]),
	contextId: z.string(),
});

type AssignmentBaseParams = z.infer<typeof AssignmentBaseParams>;

export function useRoles(
	context: AssignmentBaseParams["context"],
	contextId: string,
	raci?: ERoles,
): UseQueryResult<IModelReference[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useRoles: workspaceId is not defined");
	}
	return useQuery({
		queryKey: [workspaceId, context, contextId, raci, "roles"],
		queryFn: () =>
			apiGet<IModelReference[]>(
				`ws/${workspaceId}/${context}/${contextId}/roles`,
				{ params: { raci } },
			),
	});
}

export function useUpdateAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse<StepInstanceAssignment> | { data: undefined },
	Error,
	StepInstanceAssignment
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateAssignments: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: (assignees: StepInstanceAssignment) =>
			apiPut(`ws/${workspaceId}/${context}/${contextId}/assignments/`, {
				raci: assignees.raci,
				assignments: assignees.assignments,
			}),

		onSuccess: () => {
			const key =
				context === "step-instances"
					? "single-step-instance"
					: "process-instances";
			queryClient.invalidateQueries({
				queryKey: [workspaceId, key, contextId],
			});
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}
