import { useCloseProcessInstance } from "@metronome/api/useProcessInstance";
import { ConfirmationModal } from "@metronome/components/ConfirmationModal";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import type {
	IProcessInstance,
	UserSelectedProcessState,
} from "@metronome/types/ProcessInstance";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import { ChevronDownIcon, UpdateIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import type React from "react";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type UpdateProcessStateProps = {
	processInstance: IProcessInstance;
};
export const UpdateProcessState: React.FC<UpdateProcessStateProps> = ({
	processInstance,
}) => {
	const intl = useIntl();
	const [showConfirmation, setShowConfirmation] = useState<
		UserSelectedProcessState | false
	>(false);

	const isActive = isProcessActive(processInstance.state);

	const { mutate, isPending } = useCloseProcessInstance();

	const warningMessages = {
		close: intl.formatMessage({ id: "PROCESS_INSTANCE.CLOSE_MESSAGE" }),
		closeAndCancel: intl.formatMessage({
			id: "PROCESS_INSTANCE.CLOSE_AND_CANCEL_MESSAGE",
		}),
		open: intl.formatMessage({ id: "PROCESS_INSTANCE.RE_OPEN_MESSAGE" }),
	};

	const handleConfirmation = useCallback((): void => {
		if (showConfirmation) {
			mutate({
				processInstanceId: processInstance.id,
				type: showConfirmation,
			});
		}
		setShowConfirmation(false);
	}, [mutate, processInstance.id, showConfirmation]);

	return (
		<>
			{!!showConfirmation && (
				<ConfirmationModal
					loading={isPending}
					show={Boolean(showConfirmation)}
					onClose={() => setShowConfirmation(false)}
					onConfirm={handleConfirmation}
					message={
						warningMessages[showConfirmation as keyof typeof warningMessages]
					}
				/>
			)}

			<DropdownMenu>
				<DropdownMenuTrigger
					className={clsx({
						"border rounded-2xl py-0.5 px-2 font-semibold [&[data-state=open]>svg]:rotate-180": true,
						"border-green-800 text-green-800": isActive,
						"border-slate-800 text-slate-800": !isActive,
					})}
				>
					<>
						{!!isPending && (
							<UpdateIcon className="inline-block me-1 [vertical-align:-0.125em] animate-spin" />
						)}

						<FormattedMessage
							id={`PROCESS_INSTANCE.${processInstance.state.toUpperCase()}`}
						/>
						{!isPending && (
							<ChevronDownIcon className="inline-block ms-0.5 [vertical-align:-0.12em]" />
						)}
					</>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					{isActive ? (
						<>
							<DropdownMenuItem onSelect={() => setShowConfirmation("close")}>
								<FormattedMessage id="PROCESS_INSTANCE.CLOSE" />
							</DropdownMenuItem>
							<DropdownMenuItem
								onSelect={() => setShowConfirmation("closeAndCancel")}
							>
								<FormattedMessage id="PROCESS_INSTANCE.CLOSE_AND_CANCEL" />
							</DropdownMenuItem>
						</>
					) : (
						<>
							<DropdownMenuItem onSelect={() => setShowConfirmation("open")}>
								<FormattedMessage id="PROCESS_INSTANCE.OPEN" />
							</DropdownMenuItem>
						</>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
		</>
	);
};
