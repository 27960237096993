import { z } from "zod";
import { IParty } from "./Party";

export const IUserRoleAssignmentsMap = z.object({
	responsibleCount: z.number(),
	responsibleAssignees: z.array(IParty),
	accountableCount: z.number(),
	accountableAssignees: z.array(IParty),
	consultedCount: z.number(),
	consultedAssignees: z.array(IParty),
	informedCount: z.number(),
	informedAssignees: z.array(IParty),
});

export type IUserRoleAssignmentsMap = z.infer<typeof IUserRoleAssignmentsMap>;
