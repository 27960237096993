import { z } from "zod";
import { IModelReference } from "./ModelReference";

export const IParty = z.object({
	type: z.literal("party"),
	id: z.string().min(1),
	userId: z.string(),
	email: z.string().min(1),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	name: z.string().min(1),
	profilePictureUri: z.string().optional(),
	roles: z.array(IModelReference),
});

export type IParty = z.infer<typeof IParty>;
