import clsx from "clsx";
import { Dialog, DialogContent, DialogTrigger } from "../Dialog";

import { MixerHorizontalIcon } from "@radix-ui/react-icons";

type FilterModalProps = {
	isFiltered: boolean;
	children: React.ReactNode;
};

export const FilterModal: React.FC<FilterModalProps> = ({
	children,
	isFiltered,
}) => {
	return (
		<Dialog>
			<DialogTrigger
				className={clsx({
					"aspect-square text-slate-500 transition-colors hover:text-blue-700 p-0.5 hover:bg-blue-100 rounded-full": true,
					"!text-blue-700 !bg-blue-200": isFiltered,
				})}
			>
				<MixerHorizontalIcon />
			</DialogTrigger>
			<DialogContent size="small">{children}</DialogContent>
		</Dialog>
	);
};
