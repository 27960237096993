import type React from "react";
import type { CSSProperties } from "react";
import clsx from "clsx";
import "./Card.scss";

export const Card: React.FC<{
	className?: string;
	style?: CSSProperties;
	children: React.ReactNode;
}> = ({ children, className, style }) => (
	<div className={clsx("card mb-3 min-h-[50vh]", className)} style={style}>
		{children}
	</div>
);
