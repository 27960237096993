import { createFileRoute, useNavigate } from "@tanstack/react-router";
import StepInstancePage from "@metronome/features/step-instance/StepInstancePage";
import { Dialog, DialogContent } from "@metronome/components/Dialog";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import * as v from "valibot";
import { useState, useTransition } from "react";

const StepModal = v.object({
	stepId: v.string(),
});

export const Route = createFileRoute("/$workspaceId/your-work/steps/modal")({
	validateSearch: (searchParams) => v.parse(StepModal, searchParams),
	component: () => {
		const { stepId } = Route.useSearch();
		return <StepInstanceModal stepId={stepId} />;
	},
});

export const StepInstanceModal: React.FC<{ stepId: string }> = ({ stepId }) => {
	const navigate = useNavigate();
	const [isPending, startTransition] = useTransition();
	const workspaceId = useWorkspaceId();
	const [open, setOpen] = useState(true);

	const onOpenChange = (): void => {
		// if we arrived here from a different page, go back to that page ?
		// something like:
		// if (location.state?.from) {
		// 	// navigate(goBack)
		// 	return;
		// }

		startTransition(() => {
			setOpen(false);
		});
	};

	if (!open) {
		navigate({
			to: "/$workspaceId/your-work/steps",
			params: {
				workspaceId,
			},
		});
	}

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent>
				<StepInstancePage isSideOpen stepId={stepId} />
			</DialogContent>
		</Dialog>
	);
};
