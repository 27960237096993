import { z } from "zod";
import { IModelReference } from "./ModelReference";

export const IProcessTemplate = z.object({
	id: z.string(),
	organization: IModelReference.optional(),
	name: z.string(),
	description: z.string().optional(),
	versionName: z.union([z.string(), z.number()]).optional(),
	category: z.string().optional(),
});
export type IProcessTemplate = z.infer<typeof IProcessTemplate>;
