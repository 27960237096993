import { stepInstanceKeys } from "@metronome/api/useStepInstance";
import { useRACIData } from "@metronome/context/RACIData";
import { usePrevious } from "@metronome/hooks/usePrevious";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { UpdateIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIsFetching } from "@tanstack/react-query";
import { Checkbox } from "@metronome/components/ui/checkbox";

/**
 * Renders the Assignee/Watcher Filter component.
 * Allows users to toggle Watcher mode and displays a loading spinner when step instances are being fetched.
 */

export const RACIFilter = () => {
	const workspaceId = useWorkspaceId();
	const { RACI, setRACI } = useRACIData();
	const [isDirty, setIsDirty] = useState(false);

	const isFetchingStepInstances = useIsFetching({
		queryKey: stepInstanceKeys.all(workspaceId),
	});

	const prevIsFetching = usePrevious(isFetchingStepInstances);

	// this effect checks 1: if the user changed the RACI (isDirty), 2: if the step instances were fetcing (previous state) and 3: if they are not fetching anymore
	// it sets isDirty to false
	useEffect(() => {
		if (prevIsFetching && !isFetchingStepInstances && isDirty) {
			setIsDirty(false);
		}
	}, [isFetchingStepInstances, prevIsFetching, isDirty]);

	const toggleWatcher = (checked: boolean) => {
		if (checked) {
			setRACI(() => ["R", "A", "C", "I"]);
		} else {
			setRACI(() => ["R"]);
		}
		// indicates the user changed the Watcher mode, this is used in combination with isFetchingStepInstances to display a loading spinner
		setIsDirty(true);
	};

	return (
		<div className="flex flex-row gap-1">
			<div className="items-top flex space-x-2">
				<Checkbox
					id="terms1"
					checked={RACI.includes("A")}
					onCheckedChange={toggleWatcher}
				/>
				<div className="grid gap-1.5 leading-none">
					<label
						htmlFor="terms1"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						<FormattedMessage id="WATCHER" />
					</label>
				</div>
			</div>

			{isDirty && isFetchingStepInstances > 0 && (
				<UpdateIcon className="inline-block animate-spin" />
			)}
		</div>
	);
};
