import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import { IProcessInstance } from "@metronome/types/ProcessInstance";
import { FormattedMessage } from "react-intl";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { ProcessRaciDialog } from "../Dialogs/ProcessRaciDialog";
import { ProcessMetadataDialog } from "../Dialogs/ProcessMetadataDialog";
import { ProcessDetailsDialog } from "../Dialogs/ProcessDetailsDialog";
import { ProcessTimelineDialog } from "../Dialogs/ProcessTimelineDialog";

const ProcessInstanceDetailsInfo = (): JSX.Element => (
	<ul className="mb-0">
		<li>
			<FormattedMessage id="PROCESS_INSTANCE_DETAILS_INFO" />
		</li>
		<li>
			<FormattedMessage id="PROCESS_INSTANCE_DETAILS_TIMELINE" />
		</li>
	</ul>
);

const ProcessInstanceBusinessDimensionsInfo = (): JSX.Element => (
	<ul className="mb-0">
		<li>
			<FormattedMessage id="PROCESS_INSTANCE_BUSINESS_DIMENSIONS_INFO" />
		</li>
	</ul>
);

type ProcessDetailsMenuProps = {
	processInstance: IProcessInstance;
};
export const ProcessDetailsMenu: React.FC<ProcessDetailsMenuProps> = ({
	processInstance,
}) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger>
				<DotsVerticalIcon />
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<DropdownMenuLabel>
					<FormattedMessage id="PROCESS_DETAILS" />
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<ProcessTimelineDialog processInstance={processInstance} />
				<ProcessRaciDialog processInstance={processInstance} />
				<ProcessMetadataDialog processInstance={processInstance} />
				<ProcessDetailsDialog processInstance={processInstance} />
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
