import type React from "react";
import { useIntl } from "react-intl";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import { PageTitle } from "@metronome/context/PageData";
import { ProcessInstanceTemplate } from "../../../../features/ProcessInstanceTemplate";
import { singleProcessOptions } from "@metronome/api/useProcessInstance";
import {
	Navigate,
	Outlet,
	createFileRoute,
	useChildMatches,
} from "@tanstack/react-router";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { useSuspenseQuery } from "@tanstack/react-query";
import * as v from "valibot";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";

export const Route = createFileRoute("/$workspaceId/process/$processId")({
	parseParams: ({ workspaceId, processId }) => ({
		workspaceId: v.parse(v.string(), workspaceId),
		processId: v.parse(v.string(), processId),
	}),

	loader: ({ context, params }) =>
		context?.queryClient.ensureQueryData(
			singleProcessOptions(params.workspaceId, params.processId),
		),
	component: () => {
		const { workspaceId, processId } = Route.useParams();
		const matches = useChildMatches();
		const { data: processInstance, isSuccess } = useSuspenseQuery(
			singleProcessOptions(workspaceId, processId),
		);
		// if we dont have any child matches, we should navigate to the gates and steps page
		if (matches.length === 0) {
			return (
				<Navigate
					to="/$workspaceId/process/$processId/gates-and-steps"
					params={{ workspaceId, processId }}
				/>
			);
		}

		if (isSuccess && processInstance)
			return <ProcessInstancePage processInstance={processInstance} />;
	},
});

type ProcessInstancePageProps = {
	processInstance: IProcessInstance;
};

const ProcessInstancePage: React.FC<ProcessInstancePageProps> = ({
	processInstance,
}) => {
	const intl = useIntl();
	useSetCurrentOrganization(processInstance?.organizationId);

	const { organizations } = useOrganizationData();

	const organization = organizations?.find(
		(orga) => orga.id === processInstance?.organizationId,
	);

	if (!processInstance) {
		return (
			<span>{intl.formatMessage({ id: "NO_ACTIVE_PROCESS_INSTANCE" })}</span>
		);
	}

	return (
		<ProcessInstanceTemplate processInstance={processInstance}>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_INSTANCE" })}
			</PageTitle>
			<Outlet />
		</ProcessInstanceTemplate>
	);
};
