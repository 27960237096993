import type React from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogContent } from "./Dialog";
import { Button } from "./ui/button";

export const ConfirmationModal: React.FC<{
	show: boolean;
	onConfirm: () => void;
	loading: boolean;
	onClose: () => void;
	title?: string;
	message?: string;
}> = ({ show, onConfirm, loading, onClose, title, message }) => {
	const intl = useIntl();

	return (
		<Dialog modal open={show} onOpenChange={onClose}>
			<DialogContent size="small" data-testid="confirmation-modal">
				<div className="flex flex-col gap-2">
					<strong>{title || intl.formatMessage({ id: "ARE_YOU_SURE" })}</strong>
					<span>{message}</span>
					<div className="flex flex-row gap-2 items-center self-end">
						<Button variant="ghost" onClick={onClose}>
							{intl.formatMessage({ id: "NO" })}
						</Button>
						<Button
							disabled={loading}
							onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
								e.stopPropagation();
								e.preventDefault();
								onConfirm();
							}}
						>
							{intl.formatMessage({ id: "YES" })}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
