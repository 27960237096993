import * as React from "react";
import { Label } from "@metronome/components/ui/label";
import { SingleTimePickerInput } from "./singleTimePickerInput";
import { FormattedMessage } from "react-intl";
import { Button } from "./button";

interface TimePickerDemoProps {
	date: Date | undefined;
	setDate: (date: Date | undefined) => void;
	onSave: () => void;
}

export const TimePickerInput: React.FC<TimePickerDemoProps> = ({
	date,
	setDate,
	onSave,
}) => {
	const minuteRef = React.useRef<HTMLInputElement>(null);
	const hourRef = React.useRef<HTMLInputElement>(null);

	return (
		<div className="flex items-end gap-2">
			<div className="grid gap-1 text-center">
				<Label htmlFor="hours" className="text-xs">
					<FormattedMessage id="HOURS" />
				</Label>
				<SingleTimePickerInput
					picker="hours"
					date={date}
					setDate={setDate}
					ref={hourRef}
					onRightFocus={() => minuteRef.current?.focus()}
				/>
			</div>
			<div className="grid gap-1 text-center">
				<Label htmlFor="minutes" className="text-xs">
					<FormattedMessage id="MINUTES" />
				</Label>
				<SingleTimePickerInput
					picker="minutes"
					date={date}
					setDate={setDate}
					ref={minuteRef}
					onLeftFocus={() => hourRef.current?.focus()}
				/>
			</div>
			<Button onClick={onSave} variant="outline">
				<FormattedMessage id="SAVE" />
			</Button>
		</div>
	);
};
