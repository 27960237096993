import { BreadcrumbsResponsive } from "@metronome/components/MultiLevelBreadcrumbs";
import { ProcessStreamSummary } from "@metronome/features/ProcessStream/Summary";
import type { ILinkMultiLevel } from "@metronome/types/Link";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import {
	ClockIcon,
	CounterClockwiseClockIcon,
	LightningBoltIcon,
} from "@radix-ui/react-icons";
import { Link } from "@tanstack/react-router";
import type React from "react";
import { useIntl } from "react-intl";

type ProcessStreamTemplateProps = {
	processStream: IProcessStream;
	isLoading: boolean;
	workspaceId: string;
	children: React.ReactNode;
};

export const ProcessStreamTemplate: React.FC<ProcessStreamTemplateProps> = ({
	processStream,
	workspaceId,
	children,
	isLoading,
}) => {
	const intl = useIntl();

	const params = { workspaceId, streamId: processStream.id };

	const breadcrumbsLinks: ILinkMultiLevel[] = [
		{
			active: true,
			type: "PROCESS_STREAM",
			name: processStream.name,
			href: "/$workspaceId/stream/$streamId",
			params: {
				workspaceId,
				streamId: processStream.id,
			},
		},
	];

	return (
		<>
			<BreadcrumbsResponsive links={breadcrumbsLinks} />
			<ProcessStreamSummary
				isLoading={isLoading}
				processStream={processStream}
			/>
			<div>
				<div className="hidden md:block py-2">
					<ul className="flex flex-row">
						<li>
							<Link
								to="/$workspaceId/stream/$streamId/$state"
								params={{ ...params, state: "active" }}
								activeProps={{
									className: "font-medium text-primary-color bg-white",
								}}
								className="font-medium px-5 py-2 rounded transition-colors"
							>
								<LightningBoltIcon className="inline-block me-1 [vertical-align:-0.125em]" />
								{intl.formatMessage({ id: "PROCESS_STREAM.TABS.INSTANCES" })}
							</Link>
						</li>
						<li>
							<Link
								to="/$workspaceId/stream/$streamId/$state"
								params={{ ...params, state: "upcoming" }}
								activeProps={{
									className: "font-medium text-primary-color bg-white",
								}}
								className="font-medium px-5 py-2 rounded transition-colors"
							>
								<ClockIcon className="inline-block me-1 [vertical-align:-0.125em]" />
								{intl.formatMessage({ id: "PROCESS_STREAM.TABS.UPCOMING" })}
							</Link>
						</li>
						<li>
							<Link
								to="/$workspaceId/stream/$streamId/$state"
								params={{ ...params, state: "history" }}
								activeProps={{
									className: "font-medium text-primary-color bg-white",
								}}
								className="font-medium px-5 py-2 rounded transition-colors"
							>
								<CounterClockwiseClockIcon className="inline-block me-1 [vertical-align:-0.125em]" />
								{intl.formatMessage({ id: "PROCESS_STREAM.TABS.HISTORY" })}
							</Link>
						</li>
					</ul>
				</div>
				<div>{children}</div>
			</div>
		</>
	);
};
