import { MyProcessUserConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { createFileRoute } from "@tanstack/react-router";
import useSaveTableViews from "@metronome/api/useSaveTableViews";
import useUserPreferences from "@metronome/api/useUserPreference";
import useWorkspaceViews from "@metronome/api/useWorkspaceViews";

export const Route = createFileRoute("/$workspaceId/your-work/processes")({
	component: () => <YourWorkProcesses />,
});
const PREFERENCE_KEY = "metronome.myprocesses.active";

const YourWorkProcesses = () => {
	const { data: savedViews } = useSaveTableViews(PREFERENCE_KEY);
	const { data: workspaceViews } = useWorkspaceViews(PREFERENCE_KEY);
	const { data: preferences } = useUserPreferences(PREFERENCE_KEY);
	return (
		<ActiveViewProvider key={PREFERENCE_KEY} preferences={preferences}>
			<MyProcessUserConfigLoader
				states={["active"]}
				times={["past", "today"]}
				preferences={preferences}
				preferenceKey={PREFERENCE_KEY}
				savedViews={savedViews}
				workspaceViews={workspaceViews}
			/>
		</ActiveViewProvider>
	);
};
