import { z } from "zod";

const EIndicatorType = z.enum(["business", "process"]);

type EIndicatorType = z.infer<typeof EIndicatorType>;

export const IIndicator = z.object({
	id: z.string(),
	name: z.string(),
	value: z.string(),
	indicatorType: EIndicatorType,
	category: z.string(),
	valueVersion: z.number(),
	setOn: z.union([z.date(), z.string()]),
});

export type IIndicator = z.infer<typeof IIndicator>;
