import type React from "react";
import { AxiosError } from "axios";
import { Toaster } from "sonner";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import "react-day-picker/dist/style.css";
import { I18nProvider } from "./i18n/i18nProvider";
import { ProcessCategoriesProvider } from "@metronome/context/ProcessCategoriesData";
import { WebSocketProvider } from "@metronome/context/WebSocketContext";
import { NotFound } from "@metronome/components/NotFound";
import { AuthenticationPage } from "@metronome/features/authentication/AuthenticationLayout";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import LoadingMetronome from "./components/LoadingMetronome";

const httpErrorCodeNoRetry = [401, 400, 404];
const numberOfRetries = 2;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: true,
			staleTime: 20000,
			retry: (failureCount: number, error: unknown) => {
				if (error instanceof AxiosError && error.response?.status) {
					if (httpErrorCodeNoRetry.includes(error.response.status))
						return false;
				}
				return failureCount <= numberOfRetries;
			},
		},
	},
});

// Create a new router instance
const router = createRouter({
	routeTree,
	defaultPreload: "intent",
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
	context: {
		queryClient,
	},
	defaultNotFoundComponent: () => <NotFound />,
	defaultPendingComponent: () => <LoadingMetronome />,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

const App: React.FC = () => {
	const { inProgress } = useMsal();

	// if the login is in progress, do not render the router to preserve hash in url
	if (inProgress !== InteractionStatus.None) return null;
	return (
		<>
			<AuthenticatedTemplate>
				<QueryClientProvider client={queryClient}>
					<ReactQueryDevtools initialIsOpen={false} />
					<WebSocketProvider>
						<ProcessCategoriesProvider>
							<I18nProvider>
								<RouterProvider router={router} />
								<Toaster richColors />
							</I18nProvider>
						</ProcessCategoriesProvider>
					</WebSocketProvider>
				</QueryClientProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<I18nProvider>
					<AuthenticationPage />
				</I18nProvider>
			</UnauthenticatedTemplate>
		</>
	);
};

export default App;
